import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { api } from '../../../api/service';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ResponsivePie } from '@nivo/pie';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import { LineChart, PieChart, ColumnChart } from 'react-chartkick';
import { ResponsiveBar } from '@nivo/bar';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';
import * as moment from 'moment';
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import Icon from '@material-ui/core/Icon';
import DashBoardCard from './components/DashbaordCard';
import 'chart.js';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  control: {
    padding: theme.spacing(2),
  },
  paperColor: {
    backgroundColor: '#594f8d',
    color: 'white',
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  paperColor1: {
    backgroundColor: 'orange',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor2: {
    backgroundColor: '#594f8d',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor3: {
    backgroundColor: '#e91e63',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor4: {
    backgroundColor: '#039be5',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const MainDashboard = () => {
  const [spacing, setSpacing] = React.useState(2);
  const [data, setData] = useState({});
  const classes = useStyles();
  const history = useHistory();
  const [enquiries, setEnquires] = useState([]);
  async function getData() {
    const result = await api.dashboard.getDashboardData();
    let da = await result.data.listingStatus.map((u) => {
      return { id: u.key, value: u.count };
    });

    let xNoOfUsers = result.data.numberOfUsersCreated
      ? await result.data.numberOfUsersCreated
          .map((u) => (u._id ? u._id : '2020-08-01'))
          .sort(function (a, b) {
            return new Date(a) - new Date(b);
          })
      : [];
    let yNoOfUsers = result.data.numberOfUsersCreated
      ? await result.data.numberOfUsersCreated
          .map((u) => u.count)
          .sort(function (a, b) {
            return new Date(a) - new Date(b);
          })
      : [];

    let quickBuyListing = [
      {
        id: 'For Sale',
        value: result.data.quickBuyforSale,
      },
      {
        id: 'Sold',
        value: result.data.quickBuySold,
      },
    ];
    setData({
      ...result.data,
      listingStatus: da,
      xNoOfUsers: xNoOfUsers ? xNoOfUsers : [],
      yNoOfUsers: yNoOfUsers ? yNoOfUsers : [],
      quickBuyListing,
    });

    try {
      let pieLabels = ['For Sale', 'Sold'];
      let pieValues = [result.data.quickBuyforSale, result.data.quickBuySold];
      setPieQuickByStatus(pieValues);
      setPieOpQuickByStatus({
        chart: {},
        plotOptions: {
          pie: {
            customScale: 0.8,
          },
        },
        legend: {
          show: true,
        },
        labels: pieLabels,
      });
    } catch (err) {
      console.log(err);
    }
    try {
      let pieData = result.data.quickBuyStatus;
      let pieLabels = pieData.map((item) => item.key);
      let pieValues = pieData.map((item) => item.count);
      setPie(pieValues);
      setPieOp({
        chart: {
          // width: '100%',
          height: '90%',
        },
        plotOptions: {
          pie: {
            customScale: 1.0,
          },
        },

        legend: {
          fontSize: '8px',
          show: true,
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          offsetY: 10,
          offstX: -10,
          horizontalAlign: 'center',

          width: '10px',
          height: '100%',
          position: 'left',
        },
        labels: pieLabels,
      });
    } catch (err) {
      console.log(err);
    }
    try {
      let pieData = result.data.listingStatus;
      let pieLabels = pieData.map((item) => item.key);
      let pieValues = pieData.map((item) => item.count);
      setListingStatus(pieValues);
      setPieOpListingStatus({
        chart: {},
        plotOptions: {
          pie: {
            customScale: 0.8,
          },
        },
        legend: {
          show: true,
        },
        labels: pieLabels,
      });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getData();
  }, {});
  const [pie, setPie] = useState([]);
  const [pieOp, setPieOp] = useState({});
  const [pieQuickByStatus, setPieQuickByStatus] = useState([]);
  const [pieOpQuickByStatus, setPieOpQuickByStatus] = useState({});
  const [pieListingStatus, setListingStatus] = useState([]);
  const [pieOpListingStatus, setPieOpListingStatus] = useState({});
  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };
  return (
    <div className={classes.root}>
      <Breadcrumbs />

      <Grid container direction="row" spacing={2} justify="flex-start">
        <Grid item xs={12} lg={12} md={12} spacing={1}>
          <Grid item xs={12} lg={12} md={12} container direction="row" spacing={2}>
            <Grid item xs={12} lg={4} md={6}>
              <div style={{ background: 'white', padding: '30px 10px' }}>
                <Typography variant="overline">Quick Buy Enquiry Status</Typography>
                <ReactApexChart options={pieOp} series={pie} type="pie" />
              </div>
            </Grid>
            <Grid item xs={12} lg={4} md={6}>
              <div style={{ background: 'white', padding: '30px 30px' }}>
                <Typography variant="overline">Quick Buy</Typography>
                <ReactApexChart options={pieOpQuickByStatus} series={pieQuickByStatus} type="pie" />
              </div>
            </Grid>

            <Grid item xs={12} lg={4} md={6}>
              <div style={{ background: 'white', padding: '30px 33px' }}>
                <Typography variant="overline">Vehicle Listings</Typography>
                <ReactApexChart options={pieOpListingStatus} series={pieListingStatus} type="pie" />
              </div>
            </Grid>

            <br />
          </Grid>{' '}
          <Grid item xs={12} lg={12} md={12} container>
            <Grid item xs={8} md={8} lg={8}>
              <br />
              <Typography variant="subtitle2" component="h2">
                Car Listings
              </Typography>
              <br />
              <Grid container spacing={2} direction="row">
                <Grid item xs={4} lg={4} md={4}>
                  <DashBoardCard
                    title="Number of Listings"
                    value={data.listingCount ? data.listingCount : 0}
                    onClick={() => {
                      history.push('/listing/view');
                    }}
                    icon="leaderboard"
                    navigationText="View Listings"
                  />
                </Grid>
                <Grid item xs={4} lg={4} md={4}>
                  <DashBoardCard
                    title="Number of Listings"
                    value={data.listingCount ? data.listingCount : 0}
                    onClick={() => {
                      history.push('/listing/view');
                    }}
                    icon="leaderboard"
                    navigationText="View Listings"
                  />
                </Grid>
                <Grid item xs={4} lg={4} md={4}>
                  <DashBoardCard
                    title="Total Buy Enquiries"
                    value={data.buyingEnquiry ? data.buyingEnquiry : 0}
                    onClick={() => {
                      history.push('/enquiry');
                    }}
                    icon="email"
                    navigationText="View Enquiries"
                  />
                </Grid>
              </Grid>
              <br />
              <Typography variant="subtitle2" component="h2">
                Quick Buys
              </Typography>
              <br />
              <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                <Grid item xs={4} lg={4} md={4}>
                  <DashBoardCard
                    title="Delivered Quick Buys"
                    value={data.quickBuyDelivered ? data.quickBuyDelivered : 0}
                    onClick={() => {
                      history.push('/quick-buy/enquires');
                    }}
                    icon="add_task"
                    navigationText="Quick Buys"
                  />
                </Grid>
                <Grid item xs={4} lg={4} md={4}>
                  <DashBoardCard
                    title="Today's Quick Buys"
                    value={data.quickBuyforSale ? data.quickBuyforSale : 0}
                    onClick={() => {
                      history.push('/quick-buy');
                    }}
                    icon="calendar_today"
                    navigationText="Quick Buys"
                  />
                </Grid>
                <Grid item xs={4} lg={4} md={4}>
                  <DashBoardCard
                    title="Total Quick Buys"
                    value={data.quickBuylistingCount ? data.quickBuylistingCount : 0}
                    onClick={() => {
                      history.push('/quick-buy');
                    }}
                    icon="schedule"
                    navigationText="Quick Buys"
                  />
                </Grid>
              </Grid>
              <br />
              <Typography variant="subtitle2" component="h2">
                Users
              </Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4} md={4}>
                  <DashBoardCard
                    title="Number of Users"
                    value={data.userCount ? data.userCount : 0}
                    onClick={() => {
                      history.push('/users/view');
                    }}
                    icon="supervisor_account"
                    navigationText="View Users"
                  />
                </Grid>
              </Grid>
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default MainDashboard;

{
  /* <Grid item xs={6} lg={5} md={12}>
            <Card>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6} lg={6} md={6}>
                  <div
                    style={{
                      paddingLeft: '24px',

                      textAlign: 'left',
                    }}
                  >
                    Latest Enquiries
                  </div>
                </Grid>
                <Grid item xs={6} lg={6} md={6}>
                  <div
                    style={{
                      padding: '8px',
                      paddingRight: '24px',
                      textAlign: 'right',
                      color: '#4e4caf',
                    }}
                    onClick={() => {
                      history.push('/enquiry');
                    }}
                  >
                    View All
                  </div>
                </Grid>
              </Grid>

              <Divider light />
              <Grid item xs={12} lg={12} md={12}>
                <List className={{}}>
                  {enquiries &&
                    enquiries.map((enq) => {
                      //enq.enquiryVehicle ? (
                      return (
                        <>
                          <ListItem
                            onClick={() => {
                              history.push('/enquiry');
                            }}
                          >
                            <ListItemAvatar
                              style={{
                                marginRight: '12px',
                              }}
                            >
                              <Avatar
                                alt="Cindy Baker"
                                src={
                                  enq &&
                                  enq.enquiryVehicle &&
                                  enq.enquiryVehicle.vehicleImages &&
                                  enq.enquiryVehicle.vehicleImages[0]
                                    ? enq.enquiryVehicle.vehicleImages[0]
                                    : ''
                                }
                              />
                              <center>
                                <div
                                  style={{
                                    fontSize: '12px',
                                    textOverflow: 'ellipse',
                                  }}
                                >
                                  {enq && enq.enquiryVehicle && enq.enquiryVehicle.brand
                                    ? enq.enquiryVehicle.brand
                                    : 'N/A'}{' '}
                                  <br />
                                  {enq && enq.enquiryVehicle && enq.enquiryVehicle.model
                                    ? enq.enquiryVehicle.model
                                    : ''}
                                </div>
                              </center>
                            </ListItemAvatar>
                            <Grid xs={12} direction="column">
                              <Typography variant="subtitle2" component="h2">
                                <b>Name</b>: {enq.contactName}
                              </Typography>

                              <Typography variant="subtitle2" component="h2">
                                <b>Contact No</b>: {enq.contactNo}
                              </Typography>
                              <Grid>
                                Enquiry Message:
                                <br />
                                <b>{enq.enquiryMessage}</b>
                              </Grid>
                              <Grid>
                                <br />
                                <div
                                  style={{
                                    textAlign: 'right',
                                  }}
                                >
                                  {moment(enq.createdAt).fromNow()}
                                </div>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider light />
                        </>
                      );
                    })}
                </List>
              </Grid>
            </Card>
          </Grid> */
}

{
  /* <Grid item xs={12} lg={6} md={12}>
            <Card className={classes.root}>
              <div>Number of users registered </div>
              <br />
              <Chart
                options={{
                  chart: {
                    id: 'Date on which users registered',
                  },
                  xaxis: {
                    categories: data ? data.xNoOfUsers : [],
                  },
                }}
                series={[
                  {
                    name: 'No. of users registered',
                    data: data ? data.yNoOfUsers : [],
                  },
                ]}
                type="line"
                width="500"
              />
            </Card>
            <br />
          </Grid>
          */
}
