import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RootRef from '@material-ui/core/RootRef';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import '../Dashboard.css';
import FormikControl from '../FormikControl';
import getObjectDiffValues from '../../../utils/objectDiff';
import { FormControlLabel, Switch as MaterialSwitch, Divider } from '@material-ui/core';
import { Switch } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as moment from 'moment';

import Grid from '@material-ui/core/Grid';

import { Typeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../common/TextError';
import Tooltip from '@material-ui/core/Tooltip';
import FileUploadDialog from '../../common/SingleFileUpload/FileUploadDialog';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
const initialValues = {
  username: '',
  email: '',
  password: '',
  mobileNo: '',
  name: '',
  address: '',
  city: '',
  pincode: '',
  alternateMobileNo: '',
  aadhaar: null,
  aadhaarUrl: null,
  panCard: null,
  panCardUrl: null,
  quickBuyPayment: {
    paymentAmount: 0,
    paymentSource: 'Bank',
    paymentStatus: 'Pending',
    paymentDate: null,
    paymentRemark: '',
  },
};

const validationSchema = Yup.object({
  brand: Yup.string().min(2).required('Vehicle Brand is required'),
  model: Yup.string().required('Vehicle Model is Required'),
  variant: Yup.string().required('Vehicle Variant is Required'),
  color: Yup.string().required('Vehicle Color is Required'),
  description: Yup.string().required('Vehicle Description is Required'),
  fitnessUpto: Yup.number().min(0).max(5).positive('Enter a Valid Fitness Value ( 0 - 5 )'),
});

function DetailedUserView(props) {
  const dispatch = useDispatch();
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [updatedData, setUpdatedData] = useState(null);
  const match = useRouteMatch('/users/add/:id');
  const viewOnly = useRouteMatch('/users/view/:id');
  const [showAadhaar, setShowAadhaar] = React.useState(false);
  const [aadhaarUrl, setAadhaarUrl] = React.useState(null);
  const [showPanCardUpload, setShowPanCardUpload] = React.useState(false);
  const [panCardUrl, setPanCardUrl] = React.useState(false);
  const [aadhaarNumber, setAadhaarNumber] = React.useState(false);
  const [mobileVerified, setMobileVerified] = React.useState(false);

  const [cityValue, setCityValue] = React.useState('');
  const [cityInputValue, setCityInputValue] = React.useState('');
  const [cityOptions, setCityOptions] = React.useState([]);
  const [deleteUser, setDeleteUser] = React.useState(false);

  const history = useHistory();

  const [isVerified, setIsVerified] = React.useState(false);

  const handleIsVerifiedChange = (event) => {
    setIsVerified(event.target.checked);
  };

  const aadhaarImg = (file) => (
    <>
      {file && (
        <img
          src={file && file.preview ? file.preview : file}
          style={{
            objectFit: 'contain',
          }}
          height={120}
          width={150}
          alt="preview"
        />
      )}
    </>
  );

  const panCardImage = (file) => (
    <>
      <img
        src={file && file.preview ? file.preview : file}
        className="img-thumbnail mt-2"
        style={{
          margin: '20px',
          objectFit: 'contain',
        }}
        height={120}
        width={150}
        alt="preview"
      />
    </>
  );
  async function getDataForUpdate(id) {
    try {
      let response = await api.userManagement.getUserById(id);
      setUpdatedData(response.data);
      setCityValue(response.data.city);
      setIsVerified(response.data.isVerified);
      setCityInputValue(response.data.city);
      setAadhaarUrl(response.data.aadhaarUrl);
      setPanCardUrl(response.data.panCardUrl);
      setAadhaarNumber(response.data.aadhaar);
      setMobileVerified(response.data.isMobileVerified);
    } catch (err) {
      dispatch(setSnackbar(true, 'error', 'Something went wrong!, Not Able to find the vehicle'));
    }
  }

  useEffect(() => {
    if (match && match.params && match.params.id) {
      dispatch(setLoader(true));
      getDataForUpdate(match.params.id);
      dispatch(setLoader(false));
      setIsViewOnly(false);
    } else if (viewOnly && viewOnly.params && viewOnly.params.id) {
      dispatch(setLoader(true));
      getDataForUpdate(viewOnly.params.id);
      dispatch(setLoader(false));
      setIsViewOnly(true);
    } else {
      setIsViewOnly(false);
    }
  }, []);

  const onSubmit = async (values, onSubmitProps) => {
    dispatch(setLoader(true));
    console.log('This is called');

    if (match && match.params && match.params.id) {
      let body = await getObjectDiffValues(updatedData, values);
      try {
        let response = await api.userManagement.updateUserById(match.params.id, {
          ...body,
          aadhaarUrl,
          panCardUrl,
          aadhaar: !!aadhaarNumber ? aadhaarNumber : null,
          isMobileVerified: mobileVerified,
        });
        setUpdatedData(response.data);
        dispatch(setLoader(false));
        dispatch(setSnackbar(true, 'success', 'User Updated'));
        history.push('/users');
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
      }
    } else {
      try {
        dispatch(setLoader(true));
        let userData = {
          ...values,
          aadhaarUrl,
          panCardUrl,
          aadhaar: !!aadhaarNumber ? aadhaarNumber : null,
          isMobileVerified: mobileVerified,
        };

        let response = await api.userManagement.createUser(userData);

        onSubmitProps.resetForm();

        dispatch(setSnackbar(true, 'success', 'User Added'));
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
      }
      dispatch(setLoader(false));
    }

    dispatch(setLoader(false));
  };

  return (
    <div>
      <Breadcrumbs />
      <FileUploadDialog
        open={showAadhaar}
        documentName={'Aadhaar Card'}
        handleClose={() => {
          setShowAadhaar(false);
        }}
        passData={(data) => {
          if (data.url) {
            setAadhaarUrl(data.url);
          }
          if (data.aadhaarData && data.aadhaarData.aadhaar_number) {
            setAadhaarNumber(data.aadhaarData.aadhaar_number);
          }
          console.log(data);
        }}
      />

      <Formik
        enableReinitialize
        initialValues={updatedData ? updatedData : initialValues}
        // validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Card>
              {updatedData && (
                <Alert
                  severity={isVerified ? 'success' : 'error'}
                  style={{
                    marginTop: '3%',
                    marginRight: '3%',
                    marginLeft: '3%',
                  }}
                >
                  {isVerified ? (
                    'Users Verification is done'
                  ) : (
                    <span>
                      Users profile is pending for verification
                      {!mobileVerified && (
                        <>
                          <br />
                          Mobile Verification - <b>{mobileVerified ? 'DONE' : 'Pending'}</b>
                        </>
                      )}
                      {!aadhaarUrl && (
                        <>
                          <br />
                          Aadhaar Verification - <b>{aadhaarUrl ? 'DONE' : 'Pending'}</b>{' '}
                        </>
                      )}
                    </span>
                  )}
                </Alert>
              )}
              <Form>
                <>
                  <div className="listing-container">
                    <div className="section-left">
                      <div>
                        <FormikControl
                          control="input"
                          type="text"
                          label="Name"
                          name="name"
                          placeholder="Enter Name"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Username"
                          name="username"
                          placeholder="Enter Username"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Email"
                          name="email"
                          placeholder="Enter Email"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Mobile Number"
                          name="mobileNo"
                          placeholder="Enter Mobile Number"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Alternate Mobile Number"
                          name="alternateMobileNo"
                          placeholder="Enter Alternate Mobile Number"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Aadhaar Card"
                          name="aadhaar"
                          value={aadhaarNumber ? aadhaarNumber : ''}
                          placeholder="Enter Aadhaar Card"
                          disabled={true}
                        />
                        <br />
                        <Divider />
                        <br />
                        Aadhaar Image
                        <Grid container direction="row" justify="space-around" alignItems="center">
                          <Grid item>
                            {aadhaarUrl ? (
                              aadhaarImg(aadhaarUrl)
                            ) : (
                              <>
                                <div
                                  style={{
                                    backgroundColor: '#e0e0e0',
                                    padding: '6%',
                                    borderRadius: '4px',
                                    marginTop: '15px',
                                  }}
                                >
                                  Aadhaar Card Not Uploaded
                                </div>
                                <br />
                              </>
                            )}
                          </Grid>
                          {!isViewOnly && (
                            <Grid item>
                              {aadhaarImg ? (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setShowAadhaar(true);
                                  }}
                                  style={{
                                    float: 'right',
                                    backgroundColor: '#4caf50',
                                    color: 'white',
                                  }}
                                  disabled={isViewOnly}
                                >
                                  Upload aadhaar
                                </Button>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                          )}
                        </Grid>
                        <br />
                        <br />
                        <Divider />
                        <br />
                        {updatedData && (
                          <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                          >
                            {' '}
                            <Grid item xs={6}>
                              Account Created at <br />-{' '}
                              {moment(updatedData.createdAt).format('DD-MMM-YYYY (ddd) hh:mm')}{' '}
                              <br />- <b>{moment(updatedData.createdAt).fromNow()}</b>
                            </Grid>
                            <Grid item xs={6}>
                              {' '}
                              Account Last Updated at <br />-{' '}
                              {moment(updatedData.updatedAt).format('DD-MMM-YYYY (ddd) hh:mm')}{' '}
                              <br />- <b>{moment(updatedData.updatedAt).fromNow()}</b>
                            </Grid>
                          </Grid>
                        )}
                      </div>

                      <br />
                    </div>
                    <div className="section-right">
                      <div>
                        {/* {isViewOnly && (
                          <Button
                            variant="contained"
                            style={{
                              float: 'right',
                              backgroundColor: '#4caf50',
                              color: 'white',
                            }}
                            onClick={() => {
                              history.push(`/users/add/${viewOnly.params.id}`);
                            }}
                          >
                            Edit
                          </Button>
                        )} */}
                        <br />
                        <Tooltip title={'Mobile Verification is to be done by otp method'}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isMobileVerified"
                                checked={mobileVerified}
                                onChange={() => {
                                  setMobileVerified(!mobileVerified);
                                }}
                                disabled={isViewOnly}
                              />
                            }
                            label="Mobile Verified"
                          />
                        </Tooltip>
                        <br />
                        <br />
                        <FormikControl
                          placeholder="Enter Users Address"
                          control="textarea"
                          type="text"
                          label="Address"
                          name="address"
                          disabled={isViewOnly}
                        />
                        <label htmlFor={'city'}>City</label>
                        <br />
                        <br />
                        <Autocomplete
                          value={cityValue}
                          onChange={(event, newValue) => {
                            if (!newValue) {
                              newValue = '';
                            }
                            setCityValue(newValue);
                            formik.setFieldValue('city', newValue);
                          }}
                          freeSolo
                          inputValue={cityInputValue}
                          onInputChange={(event, newInputValue) => {
                            if (!newInputValue) {
                              newInputValue = '';
                            }
                            setCityInputValue(newInputValue);
                            formik.setFieldValue('city', newInputValue);
                          }}
                          name="city"
                          options={cityOptions}
                          style={{ width: '80%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              style={{
                                background: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '8px',
                              }}
                              placeholder="Enter City"
                            />
                          )}
                          disabled={isViewOnly}
                          fullWidth
                          required
                        />
                        <br />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Pin Code"
                          name="pincode"
                          placeholder="Enter Pin Code"
                          disabled={isViewOnly}
                        />
                        <br />
                        <Divider />
                        <br />
                        {/* Quick Buy Payment
                        <br />
                        <br /> */}
                        {/* <FormikControl
                          control="input"
                          type="text"
                          label="Amount Paid"
                          name="quickBuyPayment.paymentAmount"
                          placeholder="Enter Amount Paid"
                          disabled={isViewOnly}
                        /> */}
                        {/* <FormikControl
                          control="input"
                          type="text"
                          label="Paid Via"
                          name="quickBuyPayment.paymentSource"
                          placeholder="Enter Payment Source"
                          disabled={true}
                        /> */}
                        {/* <FormikControl
                          control="select"
                          label="Payment Status"
                          name="quickBuyPayment.paymentStatus"
                          options={[
                            { key: 'Pending', value: 'Pending' },
                            { key: 'Completed', value: 'Completed' },
                            { key: 'Rejected', value: 'Rejected' },
                          ]}
                          className="select-css"
                          disabled={isViewOnly}
                        />
                        <br /> */}
                        {/* <FormikControl
                          control="input"
                          type="text"
                          label="Payment Status"
                          name="quickBuyPayment.paymentStatus"
                          placeholder="Enter Payment Status"
                          disabled={isViewOnly}
                        /> */}
                        {/* <FormikControl
                          placeholder="Enter Payment Remark"
                          control="textarea"
                          type="text"
                          label="Payment Remark"
                          name="quickBuyPayment.paymentRemark"
                          disabled={isViewOnly}
                        /> */}
                        <br />
                        {!isViewOnly && (
                          <Button
                            type="submit"
                            className="submit-button"
                            variant="contained"
                            fullWidth
                            style={{
                              backgroundColor: '#4caf50',
                              color: 'white',
                            }}
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </Form>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
}

export default withRouter(DetailedUserView);
