import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { autoLogin } from './actions/user.action';
import './App.css';
import CarEnquiry from './components/Dashboard/CarEnquiry/CarEnquiry';
import CarListing from './components/Dashboard/CarListing/CarListing';
import ViewCarListing from './components/Dashboard/CarListing/ViewCarListing';
import ViewQuickBuyCarListing from './components/Dashboard/QuickBuy/ViewQuickBuyCarListing';
import QuickBuyCarListing from './components/Dashboard/QuickBuy/QuickBuyCarListing';

import MainDashboard from './components/Dashboard/MainDashboard/MainDashboard';
import UserManagement from './components/Dashboard/UserManagement/UserManagement';

import BrandManagement from './components/Dashboard/Brands/BrandManagement';

import Drawer from './components/Drawer/Drawer';
import Loader from './components/Loader/Loader';
import LoginComponent from './components/Login/LoginComponent';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import CustomizedSnackbars from './components/Snackbar/Snackbar';
import AuthVerifyComponent from './components/AuthComponent/AuthVerifyComponent';
import DetailedUserView from './components/Dashboard/UserManagement/UserView';
import DetailedBrandView from './components/Dashboard/Brands/BrandView';
import Settings from './components/Dashboard/Settings/Settings';

import QuickBuyEnquires from './components/Dashboard/QuickBuy/QuickBuyEnquires';
import VideoListing from './components/Dashboard/Videos/VideoListing';
import MagazineList from './components/Dashboard/Magazine/MagazineList';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

const App = () => {
  const classes = useStyles();

  const userReducer = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  return (
    <div className={classes.container}>
      <AuthVerifyComponent />
      {!userReducer.loggedIn ? null : <Drawer />}
      <div>
        {' '}
        <CustomizedSnackbars />
        <Loader />
      </div>
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => {
            return !userReducer.loggedIn ? (
              <LoginComponent {...props} />
            ) : (
              <MainDashboard {...props} />
            );
          }}
        />

        <PrivateRoute
          exact
          path="/dashboard"
          loggedIn={userReducer.loggedIn}
          component={(props) => <MainDashboard {...props} />}
        />

        <PrivateRoute
          exact
          path="/listing"
          loggedIn={userReducer.loggedIn}
          component={(props) => <ViewCarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/listing/view"
          loggedIn={userReducer.loggedIn}
          component={(props) => <ViewCarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/listing/add"
          loggedIn={userReducer.loggedIn}
          component={(props) => <CarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/listing/add/:id"
          loggedIn={userReducer.loggedIn}
          component={(props) => <CarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/listing/view/:id"
          loggedIn={userReducer.loggedIn}
          component={(props) => <CarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/listing/addvideo"
          loggedIn={userReducer.loggedIn}
          component={(props) => <VideoListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/listing/addmagazine"
          loggedIn={userReducer.loggedIn}
          component={(props) => <MagazineList {...props} />}
        />
        <PrivateRoute
          exact
          path="/quick-buy"
          loggedIn={userReducer.loggedIn}
          component={(props) => <ViewQuickBuyCarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/quick-buy/listing"
          loggedIn={userReducer.loggedIn}
          component={(props) => <ViewQuickBuyCarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/quick-buy/listing/view"
          loggedIn={userReducer.loggedIn}
          component={(props) => <ViewQuickBuyCarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/quick-buy/listing/add"
          loggedIn={userReducer.loggedIn}
          component={(props) => <QuickBuyCarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/quick-buy/listing/add/:id"
          loggedIn={userReducer.loggedIn}
          component={(props) => <QuickBuyCarListing {...props} />}
        />
        <PrivateRoute
          exact
          path="/quick-buy/listing/view/:id"
          loggedIn={userReducer.loggedIn}
          component={(props) => <QuickBuyCarListing {...props} />}
        />

        <PrivateRoute
          exact
          path="/quick-buy/enquires"
          loggedIn={userReducer.loggedIn}
          component={(props) => <QuickBuyEnquires {...props} />}
        />

        <PrivateRoute
          exact
          path="/enquiry"
          loggedIn={userReducer.loggedIn}
          component={(props) => <CarEnquiry {...props} />}
        />
        <PrivateRoute
          exact
          path="/users"
          loggedIn={userReducer.loggedIn}
          component={(props) => <UserManagement {...props} />}
        />
        <PrivateRoute
          exact
          path="/users/add/:id"
          loggedIn={userReducer.loggedIn}
          component={(props) => <DetailedUserView {...props} />}
        />
        <PrivateRoute
          exact
          path="/users/view/:id"
          loggedIn={userReducer.loggedIn}
          component={(props) => <DetailedUserView {...props} />}
        />
        <PrivateRoute
          exact
          path="/users/view"
          loggedIn={userReducer.loggedIn}
          component={(props) => <UserManagement {...props} />}
        />
        <PrivateRoute
          exact
          path="/users/add"
          loggedIn={userReducer.loggedIn}
          component={(props) => <DetailedUserView {...props} />}
        />

        <PrivateRoute
          exact
          path="/brands"
          loggedIn={userReducer.loggedIn}
          component={(props) => <BrandManagement {...props} />}
        />
        <PrivateRoute
          exact
          path="/brands/add/:id"
          loggedIn={userReducer.loggedIn}
          component={(props) => <DetailedBrandView {...props} />}
        />
        <PrivateRoute
          exact
          path="/settings"
          loggedIn={userReducer.loggedIn}
          component={(props) => <Settings {...props} />}
        />

        <Route
          exact
          from="/"
          render={(props) => {
            return !userReducer.loggedIn ? (
              <LoginComponent {...props} />
            ) : (
              <MainDashboard {...props} />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default App;
