import React from 'react';
import CheckboxGroup from '../common/CheckboxGroup';
import Input from '../common/Input';
import RadioButtons from '../common/RadioButtons';
import Select from '../common/Select';
import Textarea from '../common/Textarea';
import './FormikControl.css';

function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;
    case 'select':
      return <Select {...rest} />;
    case 'radio':
      return <RadioButtons {...rest} />;
    case 'checkbox':
      return <CheckboxGroup {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
