import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RootRef from '@material-ui/core/RootRef';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import '../Dashboard.css';
import FormikControl from '../FormikControl';
import getObjectDiffValues from '../../../utils/objectDiff';
import { FormControlLabel, Switch as MaterialSwitch, Divider } from '@material-ui/core';
import { Switch } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as moment from 'moment';

import Grid from '@material-ui/core/Grid';

import { Typeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../common/TextError';
import Tooltip from '@material-ui/core/Tooltip';
import FileUploadDialog from '../../common/SingleFileUpload/FileUploadDialog';
import GenericFileUpload from '../../common/SingleFileUpload/GenericFileUpload';

import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
const initialValues = {
  brandUrl: '',
  displayOnUi: false,
  brand: '',
};

function DetailedBrandView(props) {
  const dispatch = useDispatch();
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [updatedData, setUpdatedData] = useState(null);
  const match = useRouteMatch('/brands/add/:id');
  const viewOnly = useRouteMatch('/brands/view/:id');
  const [showBrandDialog, setShowBrandDialog] = React.useState(false);
  const [brandUrl, setBrandUrl] = React.useState(null);

  const [displayOnUi, setDisplayOnUi] = React.useState(false);

  const history = useHistory();

  const [isVerified, setIsVerified] = React.useState(false);

  const handleIsVerifiedChange = (event) => {
    setIsVerified(event.target.checked);
  };

  const brandImage = (file) => (
    <>
      {file && (
        <img
          src={file && file.preview ? file.preview : file}
          style={{
            objectFit: 'contain',
          }}
          height={120}
          width={150}
          alt="preview"
        />
      )}
    </>
  );

  async function getDataForUpdate(id) {
    try {
      let response = await api.brandModel.getBrandById(id);
      setUpdatedData(response.data);
      setDisplayOnUi(response.data.displayOnUi);
      setBrandUrl(response.data.brandUrl);
    } catch (err) {
      dispatch(setSnackbar(true, 'error', 'Something went wrong!, Not Able to find the vehicle'));
    }
  }

  useEffect(() => {
    if (match && match.params && match.params.id) {
      dispatch(setLoader(true));
      getDataForUpdate(match.params.id);
      dispatch(setLoader(false));
      setIsViewOnly(false);
    } else if (viewOnly && viewOnly.params && viewOnly.params.id) {
      dispatch(setLoader(true));
      getDataForUpdate(viewOnly.params.id);
      dispatch(setLoader(false));
      setIsViewOnly(true);
    } else {
      setIsViewOnly(false);
    }
  }, []);

  const onSubmit = async (values, onSubmitProps) => {
    dispatch(setLoader(true));
    console.log('This is called');

    if (match && match.params && match.params.id) {
      let body = await getObjectDiffValues(updatedData, values);
      try {
        let response = await api.brandModel.updateBrand(match.params.id, {
          ...body,
          brandUrl: brandUrl,
          displayOnUi,
        });
        setUpdatedData(response.data);
        dispatch(setLoader(false));
        dispatch(setSnackbar(true, 'success', 'Brand Updated'));
        history.push('/brands');
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
      }
    } else {
      try {
        dispatch(setLoader(true));
        let userData = {
          ...values,
        };

        // let response = await api.userManagement.createUser(userData);

        // onSubmitProps.resetForm();

        dispatch(setSnackbar(true, 'success', 'Brand Added'));
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
      }
      dispatch(setLoader(false));
    }

    dispatch(setLoader(false));
  };

  return (
    <div>
      <Breadcrumbs />
      <GenericFileUpload
        open={showBrandDialog}
        documentName={'Brand'}
        handleClose={() => {
          setShowBrandDialog(false);
        }}
        fileType={'brand'}
        passData={(data) => {
          if (data.url) {
            setBrandUrl(data.url);
          }
        }}
      />
      <Formik
        enableReinitialize
        initialValues={updatedData ? updatedData : initialValues}
        // validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Card>
              <Form>
                <>
                  <div className="listing-container">
                    <div className="section-left">
                      <div>
                        <FormikControl
                          control="input"
                          type="text"
                          label="Brand Name"
                          name="brand"
                          placeholder="Enter Brand Name"
                          disabled={true}
                        />
                        <Tooltip title={'Checking this will display on the frontend'}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="displayOnUi"
                                checked={displayOnUi}
                                onChange={() => {
                                  if (brandUrl) {
                                    setDisplayOnUi(!displayOnUi);
                                  } else {
                                    dispatch(
                                      setSnackbar(
                                        true,
                                        'error',
                                        'Upload Image to display on storefront',
                                      ),
                                    );
                                  }
                                }}
                                disabled={isViewOnly}
                              />
                            }
                            label="Show on Storefront"
                          />
                        </Tooltip>

                        <br />
                      </div>
                    </div>
                    <div className="section-right">
                      <br />
                      <Divider />
                      <br />
                      Brand Image
                      <Grid container direction="row" justify="space-around" alignItems="center">
                        <Grid item>
                          {brandUrl ? (
                            brandImage(brandUrl)
                          ) : (
                            <>
                              <div
                                style={{
                                  backgroundColor: '#e0e0e0',
                                  padding: '6%',
                                  borderRadius: '4px',
                                  marginTop: '15px',
                                }}
                              >
                                Brand Image Not Uploaded
                              </div>
                              <br />
                            </>
                          )}
                        </Grid>
                        {!isViewOnly && (
                          <Grid item>
                            {brandImage ? (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setShowBrandDialog(true);
                                }}
                                style={{
                                  float: 'right',
                                  backgroundColor: '#4caf50',
                                  color: 'white',
                                }}
                                disabled={isViewOnly}
                              >
                                Upload Brand Image
                              </Button>
                            ) : (
                              <div></div>
                            )}
                          </Grid>
                        )}
                      </Grid>
                      <br />
                      <br />
                      <br />
                      <br />
                      {!isViewOnly && (
                        <Grid container spacing={3} direction="column">
                          <Grid item>
                            <Button
                              type="submit"
                              className="submit-button"
                              variant="contained"
                              fullWidth
                              style={{
                                backgroundColor: '#4caf50',
                                color: 'white',
                                fontWeight: 'bolder',
                              }}
                            >
                              Submit
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              onClick={() => {
                                history.push('/brands');
                              }}
                              className="submit-button"
                              variant="contained"
                              fullWidth
                              style={{
                                backgroundColor: 'rgb(254, 176, 25)',
                                color: 'white',
                                fontWeight: 'bolder',
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </div>
                </>
              </Form>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
}

export default withRouter(DetailedBrandView);
