import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RootRef from '@material-ui/core/RootRef';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import getObjectDiffValues from '../../../utils/objectDiff';
import { FormControlLabel, Switch as MaterialSwitch, Divider } from '@material-ui/core';
import { Switch } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from '@material-ui/core/Grid';

import { Typeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../common/TextError';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

var FormData = require('form-data');

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
export default function FileUploadDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [highlighted, setHighlighted] = React.useState(false);
  const [toUpload, setToUpload] = useState([]);
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [files, setFiles] = useState([]);
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [aadhaarData, setAadhaarData] = useState(null);
  const [fetchAadharApi, setFetchAadharApi] = useState(false);
  const [spinnerMessage, setSpinnerMessage] = useState(false);
  const dispatch = useDispatch();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setSpinnerMessage('Uploading the file');

      setHighlighted(false);
      setUploadedUrl('');
      setFetchAadharApi(true);
      try {
        let tempToUpload = [...acceptedFiles];
        let urls = await Promise.all(
          tempToUpload.map(async (file) => {
            try {
              if (file.type) {
                let data = await api.fileUpload.generatePresignedUrl('aadhaar', file.type);
                let d = await axios.put(data.data.url, file, {
                  headers: {
                    'Content-Type': file.type,
                  },
                });
                return data.data.url ? data.data.url.split('?')[0] : null;
              } else {
                return file;
              }
            } catch (err) {
              return null;
            }
          }),
        );
        if (urls) {
          setUploadedUrl(urls[0]);
        }
        setToUpload(tempToUpload);
        let modifiles = acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        });
        let tempFile = [...modifiles];

        setFiles(tempFile);
      } catch (err) {
        setFetchAadharApi(false);
      }

      setFetchAadharApi(false);
    },
  });

  const images = files.map((file, index) => (
    <>
      <img
        src={file && file.preview ? file.preview : file}
        className="img-thumbnail mt-2"
        style={{
          margin: '20px',
          objectFit: 'contain',
        }}
        height={200}
        width={220}
        alt="preview"
      />
      {!isViewOnly && (
        <IconButton
          color="secondary"
          aria-label="upload picture"
          component="span"
          onClick={() => {}}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  ));
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title">Upload {props.documentName}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Upload {props.documentName}</Typography>

          {props.documentName === 'Aadhaar Card' && (
            <div>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={6} sm={6}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />

                    {fetchAadharApi == false && (
                      <p> Drag 'n' drop {props.documentName} here, or click here to select file</p>
                    )}
                  </div>
                  {images}
                </Grid>
                <Grid item xs={6} sm={6}>
                  {/* {uploadedUrl ? <div></div> : <div></div>} */}
                  {fetchAadharApi && (
                    <div>
                      <CircularProgress color="secondary" />
                      <Typography>{spinnerMessage}</Typography>
                    </div>
                  )}
                  {aadhaarData && (
                    <TableContainer component={Paper}>
                      <Table
                        className={{
                          minWidth: 650,
                        }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell align="right">Value</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {aadhaarData &&
                            Object.entries(aadhaarData).map(([key, value]) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {titleCase(key.replace('_', ' '))}
                                </TableCell>
                                <TableCell align="right">{value}</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </Grid>
              <Typography gutterBottom></Typography>

              <Tooltip disableFocusListener disableTouchListener title="Verify Aadhaar">
                <Button
                  autoFocus
                  onClick={async () => {
                    setSpinnerMessage('Verifying Aadhaar Card');

                    setFetchAadharApi(true);

                    try {
                      var data = new FormData();
                      data.append('file', toUpload[0]);

                      var config = {
                        method: 'post',
                        url: 'https://sandbox.aadhaarkyc.io/api/v1/ocr/aadhaar',
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                          Authorization:
                            'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDk3NDgyNTgsIm5iZiI6MTYwOTc0ODI1OCwianRpIjoiZDgyYTkxMjctYWJjYi00NzVlLTlkMmMtMDAxZjIxZWQxNzQ5IiwiZXhwIjoxOTI1MTA4MjU4LCJpZGVudGl0eSI6ImRldi5nYXJpYWphZ3J1dGlAYWFkaGFhcmFwaS5pbyIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyIsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJyZWFkIl19fQ.XvbZnsf5lzEA92LTIKK-gNd1oAxdWZID-4QVNvNb0Cg',
                        },
                        data: data,
                      };

                      let response = await axios(config);
                      response = response.data;
                      response = response.data;

                      if (response && response.ocr_fields && response.ocr_fields[0]) {
                        let ocr = response.ocr_fields[0];
                        let data = {};
                        if (ocr.hasOwnProperty('full_name')) {
                          data['full_name'] = ocr['full_name']['value'];
                        }
                        if (ocr.hasOwnProperty('gender')) {
                          data['gender'] = ocr['gender']['value'];
                        }
                        if (ocr.hasOwnProperty('mother_name')) {
                          data['mother_name'] = ocr['mother_name']['value'];
                        }
                        if (ocr.hasOwnProperty('dob')) {
                          data['dob'] = ocr['dob']['value'];
                        }
                        if (ocr.hasOwnProperty('aadhaar_number')) {
                          data['aadhaar_number'] = ocr['aadhaar_number']['value'];
                        }
                        setAadhaarData(data);
                        setFetchAadharApi(false);
                      }
                    } catch (err) {
                      setFetchAadharApi(false);
                    }
                  }}
                  variant="contained"
                  disabled={uploadedUrl === ''}
                  style={{
                    float: 'right',
                    backgroundColor: uploadedUrl !== '' ? '#4caf50' : 'gray',
                    color: 'white',
                  }}
                >
                  Verify Aadhaar Card
                </Button>
              </Tooltip>
            </div>
          )}

          {props.documentName === 'Pan Card' && (
            <div>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />

                    <p> Drag 'n' drop {props.documentName} here, or click here to select file</p>
                  </div>
                  {images}
                </Grid>
              </Grid>
              <Typography gutterBottom></Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              props.handleClose();
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            autoFocus
            onClick={() => {
              if (aadhaarData && aadhaarData['aadhaar_number']) {
                props.passData({
                  url: uploadedUrl,
                  aadhaarData: aadhaarData,
                });
                dispatch(setSnackbar(true, 'success', 'Aadhaar card verified'));
              } else {
                dispatch(setSnackbar(true, 'error', 'Not a valid Aadhaar card'));
              }
              props.handleClose();
            }}
            color="primary"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
