import React, { useEffect, useState } from 'react';
import {
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import { autoLogin, logout } from '../../actions/user.action';

import { makeStyles } from '@material-ui/core/styles';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import logo from '../Drawer/logo.png';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PageviewIcon from '@material-ui/icons/Pageview';
import SettingsIcon from '@material-ui/icons/Settings';
import { withRouter } from 'react-router-dom';
const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Drawer = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState('');

  const userReducer = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  const logOutClicked = () => {
    dispatch(logout());
  };
  const { history } = props;
  const classes = useStyles();
  const itemsList = [
    {
      text: 'Dashboard',
      icon: <MenuIcon />,
      onClick: () => history.push('/dashboard'),
    },
    {
      text: 'User Management',
      icon: <AccountCircleIcon />,
      onClick: () => history.push('/users/view'),
    },
    {
      text: 'Listing',
      icon: <LocalTaxiIcon />,
      collapsible: true,
      nested: [
        {
          text: 'Add Listing',
          icon: <AddCircleIcon />,
          onClick: () => history.push('/listing/add'),
        },
        {
          text: 'Add Videos',
          icon: <AddCircleIcon />,
          onClick: () => history.push('/listing/addvideo'),
        },
        {
          text: 'Add Magazine',
          icon: <AddCircleIcon />,
          onClick: () => history.push('/listing/addmagazine'),
        },
        {
          text: 'View Listing',
          icon: <ViewComfyIcon />,
          onClick: () => history.push('/listing/view'),
        },
      ],
      onClick: () => {
        setOpen(!open);
        setSelected('Listing');
      },
    },
    {
      text: 'Quick Buy',
      icon: <LocalTaxiIcon />,
      collapsible: true,
      nested: [
        {
          text: 'Add Quick Buy',
          icon: <AddCircleIcon />,
          onClick: () => history.push('/quick-buy/listing/add'),
        },
        {
          text: 'View Quick Buy Listing',
          icon: <ViewComfyIcon />,
          onClick: () => history.push('/quick-buy/listing'),
        },
        {
          text: 'Quick Buy Enquires',
          icon: <MailIcon />,
          onClick: () => history.push('/quick-buy/enquires'),
        },
      ],
      onClick: () => {
        setSelected('Quick Buy');
        setOpen(!open);
      },
    },
    {
      text: 'Enquires',
      icon: <MailIcon />,
      onClick: () => {
        history.push('/enquiry');
      },
    },
    {
      text: 'Brands',
      icon: <PageviewIcon />,
      onClick: () => {
        history.push('/brands');
      },
    },
    {
      text: 'Settings',
      icon: <SettingsIcon />,
      onClick: () => {
        history.push('/settings');
      },
    },
    {
      text: 'Logout',
      icon: <ExitToAppIcon />,
      onClick: () => {
        logOutClicked();
        history.push('/');
      },
    },
  ];
  return (
    <MUIDrawer variant="permanent" className={classes.drawer}>
      <div
        style={{
          margin: '5%',
        }}
      >
        <div
          style={{
            margin: 'auto',
            margin: 'auto',
            textAlign: 'center',
            display: 'block',
          }}
        >
          <img
            src={logo}
            style={{
              width: 80,
              height: 80,
            }}
            alt="My logo"
          />

          <Typography variant="h5" noWrap>
            Cars4all.in
          </Typography>
        </div>
      </div>
      <Divider />
      <List>
        <ListItem>Welcome {userReducer.user.username}</ListItem>
      </List>
      <Divider />
      <List>
        {itemsList.map((item, index) => {
          const { text, icon, onClick, collapsible } = item;
          return (
            <>
              {!collapsible ? (
                <ListItem button key={text} onClick={onClick}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText primary={text} />
                </ListItem>
              ) : (
                <>
                  <ListItem button key={text} onClick={onClick}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} />
                    {open && text === selected ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={open && text === selected} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.nested.map((nestedItem, index) => {
                        const { text, icon, onClick, collapsible } = nestedItem;

                        return (
                          <ListItem button key={text} onClick={onClick}>
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <ListItemText primary={text} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              )}
              <Divider />
            </>
          );
        })}
      </List>
    </MUIDrawer>
  );
};

export default withRouter(Drawer);
