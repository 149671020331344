import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Loader.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function AlertDialog() {
  const dispatch = useDispatch();

  const dialogOpen = useSelector((state) => state.loaderReducer.showDialog);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dialog
        className="loader-css"
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Please Wait, while we are processing your request...'}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CircularProgress />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
