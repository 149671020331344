import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import './VideoBtn.css';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import AWS from 'aws-sdk';

AWS.config.update({
  region: 'ap-south-1',
  accessKeyId: 'AKIAWCQ62PTAOUGMPFU3',
  secretAccessKey: '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',
});

function VideoListing(props) {
  const [videoFile, setVideoFile] = useState(null);
  const [enableButton, setEnableButton] = useState(true);
  const [folderContents, setFolderContents] = useState([]);
  const dispatch = useDispatch();
  const bucketName = 'vehicle-images-cars4all';
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVideoFile(file);
  };
  const fetchVideosFromS3 = async () => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: bucketName,
      Prefix: `${'video'}/`,
    };

    try {
      const response = await s3.listObjectsV2(params).promise();
      setFolderContents(response.Contents);
    } catch (error) {
      console.error('Error fetching videos:', error);
      return [];
    }
  };
  //  uploading video
  const uploadVideoToS3 = async () => {
    setEnableButton(false);
    const s3 = new AWS.S3();
    const params = {
      Bucket: bucketName,
      Key: `${'video'}/${videoFile.name}`, // Include the folder name in the Key
      Body: videoFile,
      ACL: 'public-read', // Set the ACL to make the uploaded video publicly accessible
      ContentType: 'video/mp4',
    };

    try {
      if (!videoFile) {
        setEnableButton(false);
        dispatch(setSnackbar(true, 'error', 'file not selected'));
      } else {
        const response = await s3.upload(params).promise();
        if (response.Location) {
          fetchVideosFromS3();
          setEnableButton(true);
          dispatch(setSnackbar(true, 'success', 'Video added'));
          setVideoFile(null);
        }
      }
    } catch (error) {
      console.error('Error uploading video:', error);
    }
  };

  //  delete video
  const deleteVideoFromS3 = async (videoFileName) => {
    const s3 = new AWS.S3();
    const videoKey = `${videoFileName}`;
    const params = {
      Bucket: bucketName,
      Key: videoKey,
    };

    try {
      const res = await s3.deleteObject(params).promise();
      if (res) {
        fetchVideosFromS3();
      }
    } catch (error) {
      console.error('Error deleting video:', error);
      console.log('Error Code:', error.code);
      console.log('Error Message:', error.message);
    }
  };

  useEffect(() => {
    fetchVideosFromS3();
  }, []);

  return (
    <div style={{ flexGrow: 1, padding: 5 }}>
      <h1>Video Upload Page</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div class="file-input">
          <input type="file" accept="video/*" onChange={handleFileChange} />
          <span class="button">Choose</span>
          <span class="label" data-js-label>
            {videoFile?.name || 'No file selected'}
          </span>
        </div>
        <div style={{ marginTop: 20 }}>
          {enableButton === true && (
            <button className="uploadbtn" onClick={uploadVideoToS3} disabled={!videoFile}>
              Upload Video
            </button>
          )}
        </div>
        <div style={{ margin: 10 }}>
          {enableButton === false && <span>Video Uploading...</span>}
        </div>
      </div>
      <div>
        <h1>Video Gallery</h1>
        <div className="video-grid">
          {folderContents.map((object) => (
            <div key={object.Key} className="video-item">
              <button className="delete-button" onClick={() => deleteVideoFromS3(object.Key)}>
                Delete
              </button>
              <video controls>
                <source
                  src={`https://vehicle-images-cars4all.s3.ap-south-1.amazonaws.com/${object.Key}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withRouter(VideoListing);
