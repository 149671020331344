import React from 'react';

function TextError(props) {
  return (
    <div
      style={{
        color: 'red',
        fontWeight: '400',
      }}
    >
      {props.children}
    </div>
  );
}

export default TextError;
