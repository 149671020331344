export const SET_LOADER = 'SET_LOADER';

const initialState = {
  showDialog: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER:
      const { showDialog } = action;
      return {
        ...state,
        showDialog,
      };
    default:
      return state;
  }
};

export const setLoader = (showDialog = false) => ({
  type: SET_LOADER,
  showDialog,
});
