import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import * as moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { api } from '../../../api/service';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import { HiEye } from 'react-icons/hi';
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteForever } from 'react-icons/md';
import { FcApproval } from 'react-icons/fc';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useParams } from 'react-router-dom';
import { Link, useLocation, BrowserRouter as Router } from 'react-router-dom';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    tabs: {
      backgroundColor: 'red',
    },
    AppBar: {
      background: 'white',
      boxShadow: 'none',
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  AppBar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  dialog: {
    appBar: {
      position: 'relative',
      marginBottom: '30vh',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  },
}));

const buyEnquiry = [
  { title: 'Enquiry Time', field: 'createdAt' },
  { title: 'Name', field: 'contactName' },
  { title: 'Email Id', field: 'email' },
  {
    title: 'Contact No',
    field: 'contactNo',
  },
  { title: 'Enquiry For Brand', field: 'brand' },
  { title: 'Enquiry For Model', field: 'model' },

  { title: 'Enquiry Message', field: 'enquiryMessage' },
];
const sellEnquiry = [
  { title: 'Enquiry Time', field: 'createdAt' },
  { title: 'Name', field: 'contactName' },
  { title: 'Email Id', field: 'email' },
  {
    title: 'Contact No',
    field: 'contactNo',
  },
  { title: 'Enquiry Message', field: 'enquiryMessage' },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DetailPanelForEnquiry({ rowData }) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const changeStatus = async (newStatus) => {
    dispatch(setLoader(true));
    try {
      let response = await api.enquiry.updateEnquiryStatus(
        rowData._id,
        newStatus.toUpperCase(),
        value,
      );
      dispatch(setSnackbar(true, 'success', `Status Updated to attempted`));
      window.location.reload(false);
    } catch (err) {
      dispatch(setSnackbar(true, 'error', `Unable to Update status to attempted`));
    }
    dispatch(setLoader(false));
  };
  useEffect(() => {
    let message =
      rowData &&
      rowData.enquiryRemarks &&
      rowData.enquiryRemarks.filter((data) => data.enquiryStatus == rowData.status)[0];
    let temp = message ? setValue(message.enquiryComment) : '';
  }, []);
  return (
    <div
      style={{
        padding: '1px',
      }}
    >
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.dialog.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                History
              </Typography>
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          <br />
          <List>
            {rowData.enquiryRemarks.map((enquiry) => (
              <>
                <ListItem button>
                  <ListItemText
                    primary={`[${enquiry.enquiryStatus}] ${enquiry.enquiryComment}`}
                    secondary={'    ' + moment(enquiry.createdAt).format('DD - MMM YYYY hh:mm a')}
                  >
                    {' '}
                  </ListItemText>{' '}
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Dialog>
      </div>
      <br />
      <TextField
        id="filled-multiline-static"
        label="Enter Enquiry Remarks"
        multiline
        rows={3}
        defaultValue={value}
        fullWidth
        style={{
          width: '70vw',
          margin: 'auto',
          display: 'block',
          fontSize: '10px',
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        variant="filled"
        helperText={() => {
          return 'Ok';
        }}
        inputProps={{}}
      />

      <div className={classes.buttonGroup}>
        <br />
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
          style={{
            margin: 'auto',
          }}
        >
          <Button
            onClick={() => {
              setValue('');
            }}
          >
            Clear
          </Button>
          {/* <Button
            style={{
              margin: 'auto',
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            View History
          </Button> */}
        </ButtonGroup>
        <br />
        Update Status
        <br />
        <br />
        <ButtonGroup
          orientation="contained"
          color="primary"
          aria-label="contained outlined primary button group"
          style={{
            margin: 'auto',
          }}
        >
          <Button
            style={{
              backgroundColor: '#039be5',
              color: 'white',
              margin: '5px',
            }}
            onClick={() => changeStatus('New')}
          >
            New
          </Button>
          <Button
            style={{
              backgroundColor: '#e91e63',
              color: 'white',
              margin: '5px',
            }}
            onClick={() => changeStatus('wip')}
          >
            WIP
          </Button>
          <Button
            style={{
              backgroundColor: '#4caf50',
              color: 'white',
              margin: '5px',
            }}
            onClick={() => changeStatus('complete')}
          >
            Complete
          </Button>
        </ButtonGroup>
        <br />
      </div>
    </div>
  );
}
function TabPanel(props) {
  let { children, value, index, ...other } = props;
  const [type, setType] = useState(props.type);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [columns, setColumns] = useState(buyEnquiry);
  const tableRef = useRef(null);
  let query = useQuery();

  async function getData() {
    const result = await api.enquiry.getEnquiresByType(type);
    switch (type) {
      case 'selling':
        setColumns(sellEnquiry);
        break;
      case 'buying':
        setColumns(buyEnquiry);
        break;
    }
    setRows(result.data);
  }

  useEffect(() => {
    getData();

    console.log(query.get('enquiryType'));
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <MaterialTable
            tableRef={tableRef}
            icons={{
              Check: () => <Check />,
              Export: () => <SaveAlt />,
              Filter: () => <FilterList />,
              FirstPage: () => <FirstPage />,
              LastPage: () => <LastPage />,
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              ThirdStateCheck: () => <Remove />,
              ViewColumn: () => <ViewColumn />,
              DetailPanel: () => <ChevronRight />,
            }}
            title="Vehicle Enquires"
            columns={[
              ...columns,
              {
                field: 'status',
                title: 'Enquiry Status',
                render: (rowData) => {
                  let style =
                    rowData.status === 'NEW'
                      ? {
                          backgroundColor: '#039be5',
                          color: 'white',
                        }
                      : rowData.status === 'COMPLETE'
                      ? {
                          backgroundColor: '#4caf50',
                          color: 'white',
                        }
                      : {
                          backgroundColor: '#e91e63',
                          color: 'white',
                        };
                  return (
                    <>
                      <Button style={style} onClick={() => {}}>
                        {rowData.status}
                      </Button>
                    </>
                  );
                },
              },
            ]}
            data={
              rows
                ? rows.map((row) => ({
                    createdAt: moment(row.createdAt).format('DD - MMM YYYY hh:mm a'),
                    contactName: row.contactName,
                    email: row.email,
                    status: row.status,
                    contactNo: row.contactNo,
                    brand: row.enquiryVehicle ? row.enquiryVehicle.brand : 'Deleted listing',
                    model: row.enquiryVehicle ? row.enquiryVehicle.model : 'Deleted listing',
                    enquiryMessage: row.enquiryMessage,
                    _id: row._id,
                    enquiryRemarks: row.enquiryRemarks,
                  }))
                : []
            }
            options={{
              filtering: false,
              actionsColumnIndex: -1,
              grouping: false,
              exportButton: false,
              add: false,
              headerStyle: {
                backgroundColor: '#4caf50',
                color: '#FFF',
                widtth: '100vw',
                fontWeight: 'bold',
              },
            }}
            detailPanel={(rowData) => {
              return <DetailPanelForEnquiry rowData={rowData} />;
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </Box>
      )}
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CarEnquiry() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const qs = useParams();
  let query = useQuery();

  useEffect(() => {
    switch (query.get('enquiryType')) {
      case 'selling':
        setValue(1);
        break;
      case 'buying':
        setValue(0);
        break;
    }
    console.log(qs);
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            style={{
              background: 'none',
            }}
            label="Buying Enquires"
            {...a11yProps(0)}
          />
          <Tab label="Selling Enquires" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} type={'buying'}>
        Buying Enquires
      </TabPanel>
      <TabPanel value={value} index={1} type={'selling'}>
        Selling Enquires
      </TabPanel>
    </div>
  );
}

//export default CarEnquiry;
