import userReducer from './user.reducer';
import snackBarReducer from './snackbar.reducer';
import loaderReducer from './loader.reducer';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  userReducer,
  snackBarReducer,
  loaderReducer,
});

export default rootReducer;
