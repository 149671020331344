import axios from 'axios';
import config from '../config/config';
const client = axios.create({
  baseURL: config.api.baseUrl,
});

export const api = {
  dashboard: {
    getDashboardData: async () => {
      try {
        return client.get(`/api/dashboard`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        return err;
      }
    },
  },
  vehicleListing: {
    createListing: async (data) => {
      try {
        return client.post(`/api/vehicles`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllListings: async () => {
      try {
        return client.get(`/api/vehicles`);
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllListingsWIthFilters: async (filters) => {
      try {
        return client.get(`/api/vehicles?${filters}`);
      } catch (err) {
        throw err.response.data;
      }
    },
    getListingById: async (listingId) => {
      try {
        return client.get(`/api/vehicles/${listingId}`);
      } catch (err) {
        throw err.response.data;
      }
    },
    updateListingById: async (listingId, body) => {
      try {
        return client.patch(`/api/vehicles/${listingId}`, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    deleteListingById: async (listingId) => {
      try {
        return client.delete(`/api/vehicles/${listingId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    updateListingStatus: async (listingId, status) => {
      try {
        return client.patch(
          `/api/vehicles/${listingId}/status`,
          {
            status: status,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  quickbuyVehicleListing: {
    createQuickBuyListing: async (data) => {
      console.log(data);
      try {
        return client.post(`/api/v1/quickbuy`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllListings: async () => {
      try {
        return client.get(`/api/v1/quickbuy`);
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllListingsWIthFilters: async (filters) => {
      try {
        return client.get(`/api/v1/quickbuy?${filters}`);
      } catch (err) {
        throw err.response.data;
      }
    },
    getListingById: async (listingId) => {
      try {
        return client.get(`/api/v1/quickbuy/${listingId}`);
      } catch (err) {
        throw err.response.data;
      }
    },
    updateListingById: async (listingId, body) => {
      try {
        return client.put(`/api/v1/quickbuy/${listingId}`, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    deleteListingById: async (listingId) => {
      try {
        return client.delete(`/api/v1/quickbuy/${listingId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    updateQuickBuyStatus: async (trackId, data) => {
      try {
        return client.put(
          `/api/v1/quickbuy/track/${trackId}/status`,
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllQuickBuysEnquires: async (filters) => {
      try {
        return client.get(`/api/v1/quickbuyEnquires?${filters}`);
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  enquiry: {
    gettopNewEnquires: async () => {
      try {
        return client.get(`/api/enquiries?sort=-createdAt&limit=2&status=new`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllEnquires: async () => {
      try {
        return client.get(`/api/enquiry`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    getEnquiresByType: async (enquiryType) => {
      try {
        return client.get(`/api/enquiry?enquiryType=${enquiryType}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    updateEnquiryStatus: async (id, status, remark) => {
      try {
        return client.put(
          `/api/enquiry/${id}/status`,
          {
            status,
            remark,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  brandModel: {
    getBrandModels: async () => {
      try {
        return client.get('/api/brand/all');
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllBrands: async () => {
      try {
        return client.get('/api/v1/brands');
      } catch (err) {
        throw err.response.data;
      }
    },
    getBrandById: async (brandId) => {
      try {
        return client.get(`/api/v1/brands/${brandId}`);
      } catch (err) {
        throw err.response.data;
      }
    },
    updateBrand: async (brandId, body) => {
      try {
        return client.put(`/api/v1/brands/${brandId}`, {
          ...body,
        });
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  fileUpload: {
    generatePresignedUrl: async (fileFolder, fileType) => {
      try {
        return client.post(
          `/api/file/${fileFolder}`,
          {
            fileType: fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  city: {
    getAllCities: async () => {
      try {
        return client.get(`/api/cities`);
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  userManagement: {
    createUser: async (data) => {
      try {
        return client.post(`/api/auth/signup`, { ...data });
      } catch (err) {
        throw err.response.data;
      }
    },
    getAllUsers: async (queryParams) => {
      try {
        return client.get(`/api/auth/users?${queryParams}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    getUserById: async (userId) => {
      try {
        console.log(`/api/auth/users/${userId}`);
        return client.get(`/api/auth/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
    updateUserById: async (userId, data) => {
      try {
        return client.put(
          `/api/auth/users/${userId}`,
          {
            ...data,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (err) {
        throw err.response.data;
      }
    },
    deleteUserById: async (userId) => {
      try {
        return client.delete(`/api/auth/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  settingManagement: {
    getSettings: async () => {
      try {
        return client.get(`/api/settings`);
      } catch (err) {
        throw err.response.data;
      }
    },
    updateSettings: async (values) => {
      try {
        return client.post(`/api/settings`, values);
      } catch (err) {
        throw err.response.data;
      }
    },
  },
  uploadVideo: {
    updateVideo: async (videoURL) => {
      try {
        return client.post(
          `/api/upload_video`,
          {
            videoURL: videoURL,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
      } catch (error) {}
    },
  },
};
