import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RootRef from '@material-ui/core/RootRef';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import '../Dashboard.css';
import FormikControl from '../FormikControl';
import getObjectDiffValues from '../../../utils/objectDiff';
import fuelTypeOptions from './CarListingConstants/fuelType';
import transmissionTypeOptions from './CarListingConstants/transmissionTypeOptions';

import insuranceOption from './CarListingConstants/insuranceOption';
import fitnesUptoOption from './CarListingConstants/fitnesUptoOption';
import { Typeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import QuickBuyBuyers from '../../common/QuickBuyBuyers/QuickBuyBuyers';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../common/TextError';
import { Grid } from '@material-ui/core';
const initialValues = {
  brand: '',
  status: 'for-sale',
  model: '',
  variant: '',
  color: '',
  fuelType: 'Petrol',
  engine: '',
  transmissionType: '',
  regYear: '',
  manfYear: '',
  kmDriven: '',
  noOfOnwers: '',
  sellCost: '',
  insurance: 'Available',
  fitnessUpto: '5',
  description: '',
  vehicleImages: [],
  city: '',
  registrationNumber: '',
  transmissionType: 'Manual',
  tags: ['Negotiable'],
  warranty: '',
  limitedEdition: false,
  Checkpoint180: 'Yes',
  tracking: [],
};

const validationSchema = Yup.object({
  brand: Yup.string().min(2).required('Vehicle Brand is required'),
  model: Yup.string().required('Vehicle Model is Required'),
  variant: Yup.string().required('Vehicle Variant is Required'),
  color: Yup.string().required('Vehicle Color is Required'),
  description: Yup.string().required('Vehicle Description is Required'),
  fitnessUpto: Yup.number().min(0).max(5).positive('Enter a Valid Fitness Value ( 0 - 5 )'),
});

function QuickBuyCarListing(props) {
  const [files, setFiles] = useState([]);
  const [highlighted, setHighlighted] = React.useState(false);
  const [toUpload, setToUpload] = useState([]);
  const dispatch = useDispatch();
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [updatedData, setUpdatedData] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const match = useRouteMatch('/quick-buy/listing/add/:id');
  const viewOnly = useRouteMatch('/quick-buy/listing/view/:id');
  const [brandOptions, setBrandOptions] = React.useState([]);
  const [brandValue, setBrandValue] = React.useState('');
  const [brandInputValue, setBrandInputValue] = React.useState('');
  const [modelValue, setModelValue] = React.useState('');
  const [modelnputValue, setModelInputValue] = React.useState('');
  const [modelOptions, setModelOptions] = React.useState([]);

  const [showBuyers, setShowBuyers] = React.useState(false);
  const [cityValue, setCityValue] = React.useState('');
  const [cityInputValue, setCityInputValue] = React.useState('');
  const [cityOptions, setCityOptions] = React.useState([]);

  const [modelResponse, setModelResponse] = React.useState([]);

  const history = useHistory();

  async function getDataForUpdate(id) {
    try {
      let response = await api.quickbuyVehicleListing.getListingById(id);
      console.log(response);
      setUpdatedData(response.data);
      setRequestData(response.data);
      setFiles(response.data.vehicleImages);
      setBrandValue(response.data.brand);
      setBrandInputValue(response.data.brand);
      setModelValue(response.data.model);
      setModelInputValue(response.data.model);
      setCityValue(response.data.city);
      setCityInputValue(response.data.city);
    } catch (err) {
      dispatch(setSnackbar(true, 'error', 'Something went wrong!, Not Able to find the vehicle'));
    }
  }

  async function setupModelOptions(brand) {
    if (brandOptions.includes(brand)) {
      let models = await modelResponse
        .filter((model) => model.brand === brand)
        .map((model) => model.models)[0]
        .filter((m) => m != '');
      await setModelOptions(models);
    }
  }
  async function getBrandModels() {
    try {
      let response = await api.brandModel.getBrandModels();
      let brands = response.data.map((d) => d.brand);
      setBrandOptions(brands);
      setModelResponse(response.data);
    } catch (err) {}
  }

  async function getCities() {
    try {
      let response = await api.city.getAllCities();
      setCityOptions(response.data);
    } catch (err) {}
  }
  useEffect(() => {
    if (match && match.params && match.params.id) {
      dispatch(setLoader(true));
      getDataForUpdate(match.params.id);
      dispatch(setLoader(false));
      setIsViewOnly(false);
    } else if (viewOnly && viewOnly.params && viewOnly.params.id) {
      dispatch(setLoader(true));
      getDataForUpdate(viewOnly.params.id);
      dispatch(setLoader(false));
      setIsViewOnly(true);
    } else {
      setIsViewOnly(false);
    }
    getBrandModels();
    getCities();
  }, []);

  const onSubmit = async (values, onSubmitProps) => {
    dispatch(setLoader(true));

    let urls = await Promise.all(
      toUpload.map(async (file) => {
        try {
          if (file.type) {
            let data = await api.fileUpload.generatePresignedUrl('vehicleImages', file.type);
            let d = await axios.put(data.data.url, file, {
              headers: {
                'Content-Type': file.type,
              },
            });
            return data.data.url ? data.data.url.split('?')[0] : null;
          } else {
            return file;
          }
        } catch (err) {
          return null;
        }
      }),
    );
    if (match && match.params && match.params.id) {
      let body = await getObjectDiffValues(updatedData, values);
      try {
        let allImages = Array.from(new Set([...updatedData.vehicleImages, ...urls]));
        let response = await api.quickbuyVehicleListing.updateListingById(match.params.id, {
          ...body,
          brand: values.brand,
          model: values.model,
          city: values.city,
          registrationNumber: values.registrationNumber,
          vehicleImages: allImages,
        });
        setUpdatedData(response.data);
        setRequestData(response.data);

        dispatch(setLoader(false));
        dispatch(setSnackbar(true, 'success', 'Listing Updated'));
        history.push('/quick-buy');
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
      }
    } else {
      try {
        dispatch(setLoader(true));
        let vehicleData = {
          ...values,
          city: values.city.trimStart().trimEnd(),
          vehicleImages: urls,
        };

        let response = await api.quickbuyVehicleListing.createQuickBuyListing(vehicleData);
        setModelValue('');
        setBrandValue('');
        setCityValue('');
        onSubmitProps.resetForm();

        setFiles([]);
        setToUpload([]);
        dispatch(setSnackbar(true, 'success', 'Listing Added'));
      } catch (err) {
        dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
      }
      dispatch(setLoader(false));
    }

    dispatch(setLoader(false));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDragEnter: () => {
      setHighlighted(true);
    },
    onDragLeave: () => {
      setHighlighted(false);
    },
    onDragOver: (e) => {
      e.preventDefault();
    },
    onDrop: async (acceptedFiles) => {
      setHighlighted(false);

      let tempToUpload = [...toUpload, ...acceptedFiles];
      setToUpload(tempToUpload);
      let modifiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      let tempFile = [...files, ...modifiles];
      setFiles(tempFile);
    },
  });

  function removeIndex(arr, index) {
    arr.splice(index, 1);
    return arr;
  }
  const images = files.map((file, index) => (
    <>
      <img
        src={file && file.preview ? file.preview : file}
        className="img-thumbnail mt-2"
        style={{
          margin: '20px',
          objectFit: 'contain',
        }}
        height={200}
        width={220}
        alt="preview"
      />
      {!isViewOnly && (
        <IconButton
          color="secondary"
          aria-label="upload picture"
          component="span"
          onClick={() => {
            if (typeof file == 'string') {
              let kindex = updatedData.vehicleImages.indexOf(file);

              if (kindex != -1) {
                setUpdatedData({
                  ...updatedData,
                  vehicleImages: removeIndex([...updatedData.vehicleImages], kindex),
                });
                let tempFile = [...files];
                tempFile.splice(index, 1);
                setFiles(tempFile);
              }
            } else {
              let tempFile = [...files];
              let tempToUpload = [...toUpload];
              tempFile.splice(index, 1);
              tempToUpload.splice(index, 1);
              setFiles(tempFile);
              setToUpload(tempToUpload);
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  ));

  return (
    <div>
      <Breadcrumbs />
      <QuickBuyBuyers
        open={showBuyers}
        handleClose={() => {
          setShowBuyers(false);
        }}
        tracking={updatedData && updatedData.tracking ? updatedData.tracking : []}
        vehicleName={updatedData ? `${updatedData.brand} ${updatedData.model}` : ''}
      />
      <Formik
        enableReinitialize
        initialValues={updatedData ? updatedData : initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Card>
              <Form>
                <>
                  <div className="listing-container">
                    <div className="section-left">
                      <div>
                        <label htmlFor={'brand'}>Brand</label>
                        <br />
                        <br />
                        <Autocomplete
                          value={brandValue}
                          onChange={(event, newValue) => {
                            if (!newValue) {
                              newValue = '';
                            }
                            setBrandValue(newValue);
                            formik.setFieldValue('brand', newValue);
                          }}
                          freeSolo
                          inputValue={brandInputValue}
                          onInputChange={(event, newInputValue) => {
                            if (!newInputValue) {
                              newInputValue = '';
                            }
                            setBrandInputValue(newInputValue);
                            setupModelOptions(newInputValue);
                            formik.setFieldValue('brand', newInputValue);
                          }}
                          name="brand"
                          options={brandOptions}
                          style={{ width: '80%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              style={{
                                background: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '8px',
                              }}
                              placeholder="Enter Brand"
                            />
                          )}
                          disabled={isViewOnly}
                          fullWidth
                          required
                        />
                        <ErrorMessage component={TextError} name={'brand'} />
                        <br />
                        <label htmlFor={'model'}>Model</label>
                        <br />
                        <br />
                        <Autocomplete
                          value={modelValue}
                          onChange={(event, newValue) => {
                            if (!newValue) {
                              newValue = '';
                            }
                            setModelValue(newValue);
                            formik.setFieldValue('model', newValue);
                          }}
                          name="model"
                          freeSolo
                          inputValue={modelnputValue}
                          onInputChange={(event, newInputValue) => {
                            if (!newInputValue) {
                              newInputValue = '';
                            }
                            setModelInputValue(newInputValue);
                            formik.setFieldValue('model', newInputValue);
                          }}
                          options={modelOptions}
                          style={{ width: '80%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                background: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '8px',
                              }}
                              placeholder="Enter Model"
                              fullWidth
                            />
                          )}
                          disabled={isViewOnly}
                          fullWidth
                          required
                        />
                        <br />
                        <ErrorMessage component={TextError} name={'model'} />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Variant"
                          name="variant"
                          placeholder="Enter Variant"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Vehicle Color"
                          name="color"
                          placeholder="Enter Color"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Registration Year"
                          name="regYear"
                          placeholder="Enter Registration Year"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Manufacturing Year"
                          name="manfYear"
                          placeholder="Enter Manufacturing Year"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="text"
                          label="Warranty"
                          name="warranty"
                          placeholder="Enter Warranty"
                          disabled={isViewOnly}
                        />
                        {/* <FormikControl
                          control="input"
                          type="text"
                          label="Registration Number"
                          name="registrationNumber"
                          placeholder="Enter Registration Number"
                          disabled={isViewOnly}
                        /> */}
                        <FormikControl
                          control="input"
                          type="number"
                          label="Fitness Value"
                          name="fitnessUpto"
                          placeholder="Enter Fitness Value"
                          disabled={isViewOnly}
                          min={0}
                          max={5}
                          step={0.1}
                        />
                        <br />
                        <FormikControl
                          control="select"
                          label="Fuel Type"
                          name="fuelType"
                          options={fuelTypeOptions}
                          className="select-css"
                          disabled={isViewOnly}
                        />
                        <br />
                        <FormikControl
                          control="select"
                          label="Transmission Type"
                          name="transmissionType"
                          options={transmissionTypeOptions}
                          className="select-css"
                          disabled={isViewOnly}
                        />{' '}
                        <br />
                        <FormikControl
                          control="select"
                          label="Tags"
                          name="tags"
                          options={[
                            { key: 'Fixed Price', value: 'Fixed Price' },
                            { key: 'Negotiable', value: 'Negotiable' },
                          ]}
                          className="select-css"
                          disabled={isViewOnly}
                        />
                      </div>
                      <br />
                    </div>
                    <div className="section-right">
                      <div>
                        {isViewOnly && (
                          <Button
                            variant="contained"
                            style={{
                              float: 'right',
                              backgroundColor: '#4caf50',
                              color: 'white',
                            }}
                            onClick={() => {
                              history.push(`/quick-buy/listing/add/${viewOnly.params.id}`);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                        {/* {updatedData && updatedData.tracking.length > 0 ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              setShowBuyers(true);
                            }}
                          >
                            Enquiries
                          </Button>
                        ) : (
                          <></>
                        )} */}
                        <br />
                        <br />

                        <FormikControl
                          placeholder="Enter Vehicle Description"
                          control="textarea"
                          type="text"
                          label="Vehicle Description"
                          name="description"
                          disabled={isViewOnly}
                        />

                        <FormikControl
                          control="input"
                          type="number"
                          label="Number of km(s) driven"
                          name="kmDriven"
                          placeholder="Enter KM(s) driven"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="number"
                          label="Number of Owners"
                          name="noOfOnwers"
                          placeholder="Enter Number of Owners"
                          disabled={isViewOnly}
                        />
                        <FormikControl
                          control="input"
                          type="number"
                          label="Selling Cost"
                          name="sellCost"
                          placeholder="Selling Cost"
                          disabled={isViewOnly}
                        />

                        <FormikControl
                          control="select"
                          label="Insurance"
                          name="insurance"
                          options={insuranceOption}
                          className="select-css"
                          disabled={isViewOnly}
                        />
                        <br />
                        <label htmlFor={'city'}>City</label>
                        <br />
                        <br />
                        <Autocomplete
                          value={cityValue}
                          onChange={(event, newValue) => {
                            if (!newValue) {
                              newValue = '';
                            }
                            setCityValue(newValue);
                            formik.setFieldValue('city', newValue);
                          }}
                          freeSolo
                          inputValue={cityInputValue}
                          onInputChange={(event, newInputValue) => {
                            if (!newInputValue) {
                              newInputValue = '';
                            }
                            setCityInputValue(newInputValue);
                            formik.setFieldValue('city', newInputValue);
                          }}
                          name="city"
                          options={cityOptions}
                          style={{ width: '80%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              style={{
                                background: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '8px',
                              }}
                              placeholder="Enter City"
                            />
                          )}
                          disabled={isViewOnly}
                          fullWidth
                          required
                        />
                        {/* <br /> */}
                        {/* <FormikControl
                          control="select"
                          label="Limited Edition"
                          name="limitedEdition"
                          options={[
                            { key: 'Yes', value: true },
                            { key: 'No', value: false },
                          ]}
                          className="select-css"
                          disabled={isViewOnly}
                        /> */}

                        <br />
                        <FormikControl
                          control="select"
                          label="Status "
                          name="status"
                          options={[
                            { key: 'For Sale', value: 'for-sale' },
                            { key: 'Sold', value: 'sold' },
                          ]}
                          className="select-css"
                          disabled={isViewOnly}
                        />
                        {/* <br />
                        <FormikControl
                          control="select"
                          label="180 Checkpoint"
                          name="Checkpoint180"
                          options={[
                            { key: 'Yes', value: 'Yes' },
                            { key: 'No', value: 'No' },
                          ]}
                          className="select-css"
                          disabled={isViewOnly}
                        /> */}
                        <br />

                        <RootRef>
                          <Paper {...getRootProps()}>
                            {!isViewOnly && (
                              <>
                                <input {...getInputProps()} />
                                <p
                                  style={{
                                    padding: '3%',
                                    backgroundColor: '#e0e0e0',
                                  }}
                                >
                                  Drag 'n' drop some image here, or click to select files
                                </p>
                              </>
                            )}
                          </Paper>
                          <br />
                          <div style={{ padding: '2px', fontWeight: 'bold' }}>
                            {' '}
                            Vehicle Listing Images{' '}
                          </div>
                          <br />
                          <Paper elevation={0}>
                            {images && images.length > 0 ? (
                              images
                            ) : (
                              <>
                                <div
                                  style={{
                                    backgroundColor: '#e0e0e0',
                                    padding: '3%',
                                    borderRadius: '4px',
                                  }}
                                >
                                  No Images found for this listing
                                </div>
                                <br />
                              </>
                            )}
                          </Paper>
                        </RootRef>

                        {!isViewOnly && (
                          <Grid container spacing={3} direction="column">
                            <Grid item>
                              <Button
                                type="submit"
                                className="submit-button"
                                variant="contained"
                                fullWidth
                                style={{
                                  backgroundColor: '#4caf50',
                                  color: 'white',
                                }}
                              >
                                Submit
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                onClick={() => {
                                  history.push('/quick-buy');
                                }}
                                className="submit-button"
                                variant="contained"
                                fullWidth
                                style={{
                                  backgroundColor: 'rgb(254, 176, 25)',
                                  color: 'white',
                                  fontWeight: 'bolder',
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </Form>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
}

export default withRouter(QuickBuyCarListing);
