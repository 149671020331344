import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { autoLogin, logout } from '../../actions/user.action';
import * as JWT from 'jwt-decode';
import React, { useEffect } from 'react';
import { setSnackbar } from '../../reducers/snackbar.reducer';

const AuthVerifyComponent = ({ history }) => {
  const userReducer = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  history.listen(() => {
    // <--- Here you subscribe to the route change
    if (localStorage.getItem('token')) {
      const jwt_Token_decoded = JWT(localStorage.getItem('token'));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        localStorage.clear();
        dispatch(logout());
        dispatch(setSnackbar(true, 'error', 'Need to relogin'));
        history.push('/login');
      }
    } else {
    }
  });
  return <div></div>;
};

export default withRouter(AuthVerifyComponent);
