import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import '../Dashboard.css';
import FormikControl from '../FormikControl';

import { Grid } from '@material-ui/core';
const initialValues = {
  callUs: '',
};

function Settings(props) {
  const dispatch = useDispatch();
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [updatedData, setUpdatedData] = useState(null);
  const [requestData, setRequestData] = useState(null);

  const history = useHistory();

  async function getDataForUpdate() {
    try {
      let response = await api.settingManagement.getSettings();
      setUpdatedData(response.data);
      setRequestData(response.data);
    } catch (err) {
      dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
    }
  }

  useEffect(() => {
    dispatch(setLoader(true));
    getDataForUpdate();
    dispatch(setLoader(false));
    setIsViewOnly(true);
  }, []);

  const onSubmit = async (values, onSubmitProps) => {
    dispatch(setLoader(true));

    try {
      let response = await api.settingManagement.updateSettings({
        ...values,
      });
      setUpdatedData(response.data);
      setRequestData(response.data);

      dispatch(setLoader(false));
      dispatch(setSnackbar(true, 'success', 'Settings Updated'));
    } catch (err) {
      dispatch(setSnackbar(true, 'error', 'Something went wrong!'));
    }

    dispatch(setLoader(false));
  };

  return (
    <div>
      <Breadcrumbs />
      <Formik
        enableReinitialize
        initialValues={updatedData ? updatedData : initialValues}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Card>
              <Form>
                <>
                  <div className="listing-container">
                    <div className="section-left">
                      <div>
                        <FormikControl
                          control="input"
                          type="text"
                          label="Call Us"
                          name="callUs"
                          placeholder="Enter callUs"
                        />
                      </div>
                      <br />

                      <Grid container spacing={3} direction="row">
                        <Grid item>
                          <Button
                            type="submit"
                            className="submit-button"
                            variant="contained"
                            fullWidth
                            style={{
                              backgroundColor: '#4caf50',
                              color: 'white',
                            }}
                          >
                            Update Settings
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            onClick={() => {
                              history.push('/dashboard');
                            }}
                            className="submit-button"
                            variant="contained"
                            fullWidth
                            style={{
                              backgroundColor: 'rgb(254, 176, 25)',
                              color: 'white',
                              fontWeight: 'bolder',
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="section-right">
                      <div></div>
                    </div>
                  </div>
                </>
              </Form>
            </Card>
          );
        }}
      </Formik>
    </div>
  );
}

export default withRouter(Settings);
