import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NoAccess from '../../components/NoAccess/NoAccess';
const PrivateRoute = ({ component: Comp, loggedIn, path, from, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedIn ? (
          <Comp {...props} />
        ) : (
          <NoAccess />
          // <Redirect
          //   to={{
          //     pathname: '/',
          //     state: {
          //       prevLocation: path,
          //       error: 'You need to login first!',
          //     },
          //   }}
          // />
        );
      }}
    />
  );
};

export default PrivateRoute;
