import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import './Magazine.css';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import AWS from 'aws-sdk';

// config
AWS.config.update({
  region: 'ap-south-1',
  accessKeyId: 'AKIAWCQ62PTAOUGMPFU3',
  secretAccessKey: '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',
});

function MagazineListing(props) {
  const [videoFile, setVideoFile] = useState(null);
  const [enableButton, setEnableButton] = useState(true);
  const [folderContents, setFolderContents] = useState([]);
  const dispatch = useDispatch();
  const bucketName = 'vehicle-images-cars4all';
  const fileInputRef = useRef(null);
  const [magazineName, setMagazineName] = useState();

  const handleInputChange = (event) => {
    setMagazineName(event.target.value);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      // Process the PDF file here, e.g., upload to server or display in the UI
      setVideoFile(file);
    } else {
      // Handle error if the selected file is not a PDF
      dispatch(setSnackbar(true, 'error', 'Select PDF file only'));
    }
  };
  const fetchVideosFromS3 = async () => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: bucketName,
      Prefix: `${'magazine'}/`,
    };

    try {
      const response = await s3.listObjectsV2(params).promise();
      setFolderContents(response.Contents);
    } catch (error) {
      console.error('Error fetching videos:', error);
      return [];
    }
  };
  //  uploading video
  const uploadVideoToS3 = async () => {
    setEnableButton(false);
    const s3 = new AWS.S3();
    const params = {
      Bucket: bucketName,
      Key: `${'magazine'}/${magazineName}`, // Include the folder name in the Key
      Body: videoFile,
      ACL: 'public-read', // Set the ACL to make the uploaded video publicly accessible
      ContentType: videoFile.type,
    };

    try {
      if (!videoFile) {
        setEnableButton(false);
        dispatch(setSnackbar(true, 'error', 'file not selected'));
      } else {
        const response = await s3.upload(params).promise();
        if (response.Location) {
          fetchVideosFromS3();
          setEnableButton(true);
          dispatch(setSnackbar(true, 'success', 'Magazine added sucessfully'));
          setVideoFile(null);
        }
      }
    } catch (error) {
      dispatch(setSnackbar(true, 'Error', 'Something worng try after some time.'));
    }
  };

  //  delete video
  const deleteVideoFromS3 = async (videoFileName) => {
    const s3 = new AWS.S3();
    const videoKey = `${videoFileName}`;
    const params = {
      Bucket: bucketName,
      Key: videoKey,
    };

    try {
      const res = await s3.deleteObject(params).promise();
      if (res) {
        fetchVideosFromS3();
      }
    } catch (error) {
      console.error('Error deleting video:', error);
      console.log('Error Code:', error.code);
      console.log('Error Message:', error.message);
    }
  };

  useEffect(() => {
    fetchVideosFromS3();
  }, []);

  return (
    <div style={{ flexGrow: 1, padding: 5 }}>
      <h1>Document Depot</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div class="file-input">
          <input type="file" ref={fileInputRef} onChange={handleFileChange} />
          <span class="button">Choose</span>
          <span class="label" data-js-label>
            {videoFile?.name || 'No file selected'}
          </span>
        </div>
        <div className="container">
          <div className="textlabel">
            <label htmlFor="magazineName">Magazine Name:</label>
          </div>
          <input
            type="text"
            id="magazineName"
            value={magazineName}
            onChange={handleInputChange}
            required
            className="textinput"
          />
        </div>
        <div style={{ marginTop: 10 }}>
          {enableButton === true && (
            <button
              className={!videoFile || !magazineName ? 'disablebtn' : 'uploadbtn'}
              onClick={uploadVideoToS3}
              disabled={!videoFile || !magazineName}
            >
              Upload Magazine
            </button>
          )}
        </div>
        <div style={{ margin: 10 }}>{enableButton === false && <span>File Uploading...</span>}</div>
      </div>
      <div>
        <h1>Publication Gallery</h1>
        <div className="video-grid2">
          {folderContents.map((object) => (
            <div key={object.Key}>
              <div class="pdf-card">
                <div class="card-actions">
                  <button class="download-link" onClick={() => deleteVideoFromS3(object.Key)}>
                    Delete
                  </button>
                </div>
                <embed
                  src={`https://vehicle-images-cars4all.s3.ap-south-1.amazonaws.com/${object.Key}`}
                  type="application/pdf"
                  width="100%"
                  height="150"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withRouter(MagazineListing);
