import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { HiEye } from 'react-icons/hi';
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteForever } from 'react-icons/md';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { set } from 'date-fns';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  control: {
    padding: theme.spacing(2),
  },
  paperColor: {
    backgroundColor: '#594f8d',
    color: 'white',
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  paperColor1: {
    backgroundColor: 'orange',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor2: {
    backgroundColor: '#594f8d',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor3: {
    backgroundColor: '#e91e63',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor4: {
    backgroundColor: '#039be5',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

function TabPanel(props) {
  const { children, value, index, type, ...other } = props;
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  async function getData() {
    dispatch(setLoader(true));
    let result = await api.brandModel.getAllBrands();
    result = result && result.data && result.data.data ? result.data.data : [];
    setRows(result);
    dispatch(setLoader(false));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <MaterialTable
            tableLayout="auto"
            icons={{
              Check: () => <Check />,
              Export: () => <SaveAlt />,
              Filter: () => <FilterList />,
              FirstPage: () => <FirstPage />,
              LastPage: () => <LastPage />,
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              ThirdStateCheck: () => <Remove />,
              ViewColumn: () => <ViewColumn />,
              DetailPanel: () => <ChevronRight />,
            }}
            title="Brand Management"
            columns={[
              { title: 'Brand Name', field: 'brand' },
              {
                title: 'Visibility',
                field: 'displayOnUi',
                filtering: false,
                grouping: false,
                render: (rowData, index) => {
                  return (
                    <Checkbox
                      checked={rowData.displayOnUi}
                      name="checkedA"
                      onChange={async () => {
                        if (rowData.brandUrl) {
                          try {
                            dispatch(setLoader(true));
                            let response = await api.brandModel.updateBrand(rowData.id, {
                              displayOnUi: !rowData.displayOnUi,
                            });
                            const dataUpdate = [...rows];
                            const index = rowData.tableData.id;
                            dataUpdate[index] = response.data;
                            setRows([...dataUpdate]);
                            dispatch(setSnackbar(true, 'success', 'Brand Updated'));
                          } catch (err) {
                            dispatch(setSnackbar(true, 'error', 'Brand Update Failed'));
                          } finally {
                            dispatch(setLoader(false));
                          }
                        } else {
                          dispatch(setSnackbar(true, 'error', 'Add Image first'));
                        }
                      }}
                    />
                  );
                },
              },
              {
                title: 'Brand Image',
                field: 'brandUrl',
                filtering: false,
                grouping: false,
                render: (rowData) => {
                  return rowData.brandUrl ? (
                    <Avatar alt="Remy Sharp" src={rowData.brandUrl} />
                  ) : (
                    'No Image'
                  );
                },
              },
            ]}
            data={
              rows
                ? rows.map((row) => ({
                    ...row,
                    aadhaar: row.aadhaar ? row.aadhaar : 'Pending',
                    panCard: row.panCard ? row.panCard : 'Pending',
                  }))
                : []
            }
            localization={{
              body: {
                emptyDataSourceMessage: 'No Brands found',
              },
              pagination: { labelRowsPerPage: 10 },
              grouping: {
                placeholder: 'Group By',
              },
            }}
            options={{
              actionsColumnIndex: -1,
              grouping: false,
              exportButton: false,
              add: false,
              columnsButton: true,
              exportAllData: true,
              exportButton: true,
              add: false,
              headerStyle: {
                backgroundColor: '#4caf50',
                color: '#FFF',
                widtth: '100vw',
                fontWeight: 'bold',
              },
              loadingType: 'overlay',
              paginationType: 'stepped',
              pageSize: 5,
              pageSizeOptions: [5, 15, 30],
              rowStyle: (rowData) => ({
                backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
                textAlign: 'center',
              }),
              filtering: true,
            }}
            onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
            actions={[
              {
                icon: () => (
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <MdModeEdit />
                    <div
                      style={{
                        fontSize: '10px',
                      }}
                    >
                      Edit
                    </div>
                  </div>
                ),
                tooltip: 'Edit Brand',
                onClick: (event, rowData) => {
                  history.push(`/brands/add/${rowData._id}`);
                },
              },
            ]}
          />
        </Box>
      )}
    </div>
  );
}

const BrandManagement = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs />

      <TabPanel value={value} index={0} type={'pending-user'}>
        Brands
      </TabPanel>
    </div>
  );
};
export default BrandManagement;
