import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { HiEye } from 'react-icons/hi';
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteForever } from 'react-icons/md';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    tabs: {
      backgroundColor: 'red',
    },
    AppBar: {
      background: 'transparent',
      boxShadow: 'none',
      width: '60%',
    },
  },
  AppBar: {
    background: 'transparent',
    boxShadow: 'none',
  },
}));

function TabPanel(props) {
  const { children, value, index, type, ...other } = props;
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  async function getData() {
    dispatch(setLoader(true));
    let queryFor = props.type;
    let queryParam = `status=${queryFor ? queryFor : 'for-sale'}`;
    const result = await api.vehicleListing.getAllListingsWIthFilters(queryParam);
    setRows(result.data);
    dispatch(setLoader(false));
  }

  useEffect(() => {
    getData();
  }, []);

  function removeIndex(arr, index) {
    arr.splice(index, 1);
    return arr;
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <MaterialTable
            tableLayout="auto"
            icons={{
              Check: () => <Check />,
              Export: () => <SaveAlt />,
              Filter: () => <FilterList />,
              FirstPage: () => <FirstPage />,
              LastPage: () => <LastPage />,
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              ThirdStateCheck: () => <Remove />,
              ViewColumn: () => <ViewColumn />,
              DetailPanel: () => <ChevronRight />,
            }}
            title="Vehicle Listing"
            columns={[
              { title: 'Vehicle Brand', field: 'brand' },
              { title: 'Vehicle Model', field: 'model' },
              { title: 'Vehicle Variant', field: 'variant' },
              { title: 'Registration Year', field: 'regYear' },
              { title: 'Selling Cost', field: 'sellCost' },
              { title: 'Insurance', field: 'insurance' },
              { title: 'City', field: 'city' },

              {
                title: 'Status',
                field: 'status',
              },
            ]}
            data={
              rows
                ? rows.map((row) => ({
                    ...row,
                    status: row.status ? row.status : 'for-sale',
                    sellCost: Intl.NumberFormat('en-IN').format(row.sellCost),
                  }))
                : []
            }
            localization={{
              body: {
                emptyDataSourceMessage: 'No Vehicle Listing Found',
              },
              grouping: {
                placeholder: 'Group By',
              },
            }}
            options={{
              actionsColumnIndex: -1,
              grouping: true,
              exportButton: false,
              add: false,
              headerStyle: {
                backgroundColor: '#4caf50',
                color: '#FFF',
                widtth: '100vw',
                fontWeight: 'bold',
              },
              rowStyle: (rowData) => ({
                backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
                textAlign: 'center',
              }),
            }}
            onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
            actions={[
              {
                icon: () => (
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <HiEye />
                    <div
                      style={{
                        fontSize: '10px',
                      }}
                    >
                      View
                    </div>
                  </div>
                ),
                tooltip: 'View Listing',
                onClick: (event, rowData) => {
                  history.push(`/listing/view/${rowData._id}`);
                },
              },
              {
                icon: () => (
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <Check />
                    <div
                      style={{
                        fontSize: '10px',
                        width: '30px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {props.type === 'sold' ? 'Mark as For Sale' : 'Mark as Sold'}
                    </div>
                  </div>
                ),
                tooltip: '' + (props.type === 'sold' ? 'Mark as For Sale' : 'Mark as Sold'),
                onClick: async (event, rowData) => {
                  dispatch(setLoader(true));

                  if (rowData.status == 'sold') {
                    try {
                      let response = await api.vehicleListing.updateListingStatus(
                        rowData._id,
                        'for-sale',
                      );
                      dispatch(setSnackbar(true, 'success', `Status Updated to for-sale`));
                      window.location.reload(false);
                    } catch (err) {
                      dispatch(setSnackbar(true, 'error', `Unable to Update status to for-sale`));
                    }
                  } else {
                    try {
                      let response = await api.vehicleListing.updateListingStatus(
                        rowData._id,
                        'sold',
                      );
                      dispatch(setSnackbar(true, 'success', `Status Updated to sold`));
                      window.location.reload(false);
                    } catch (err) {
                      dispatch(setSnackbar(true, 'error', `Unable to Update status to sold`));
                    }
                  }
                  await getData();
                  dispatch(setLoader(false));
                },
              },
              {
                icon: () => (
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <MdModeEdit />
                    <div
                      style={{
                        fontSize: '10px',
                      }}
                    >
                      Edit
                    </div>
                  </div>
                ),
                tooltip: 'Edit Listing',
                onClick: (event, rowData) => {
                  history.push(`/listing/add/${rowData._id}`);
                },
              },
              {
                icon: () => (
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <MdDeleteForever />
                    <div
                      style={{
                        fontSize: '10px',
                      }}
                    >
                      Delete
                    </div>
                  </div>
                ),
                tooltip: 'Edit Listing',
                onClick: (event, oldData) => {
                  new Promise(async (resolve, reject) => {
                    dispatch(setLoader(true));
                    try {
                      await api.vehicleListing.deleteListingById(oldData._id);
                      const data = rows.filter((row) => row._id != oldData._id);
                      setRows(data);
                      dispatch(setSnackbar(true, 'success', 'Listing Deleted'));
                      resolve();
                    } catch (err) {
                      setRows(rows);
                      dispatch(setSnackbar(true, 'error', 'Listing Deleted'));
                      reject();
                    }
                    dispatch(setLoader(false));
                  });
                },
              },
            ]}
          />
        </Box>
      )}
    </div>
  );
}

function ViewCarListing() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs />
      <AppBar
        position="static"
        color="default"
        style={{
          width: '40%',
          marginLeft: '2%',
          backgroundColor: 'white',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            style={{
              background: 'none',
            }}
            label="For Sale Vehicles"
            {...a11yProps(0)}
          />
          <Tab label="Sold Vehicles" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} type={'for-sale'}>
        For Sale Vehicles
      </TabPanel>
      <TabPanel value={value} index={1} type={'sold'}>
        Sold Vehicles
      </TabPanel>
    </div>
  );
}

export default withRouter(ViewCarListing);
