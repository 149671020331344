import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { HiEye } from 'react-icons/hi';
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteForever } from 'react-icons/md';

import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteDialog from '../../common/DeleteDialog/DeleteDialog';
import { set } from 'date-fns';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  control: {
    padding: theme.spacing(2),
  },
  paperColor: {
    backgroundColor: '#594f8d',
    color: 'white',
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  paperColor1: {
    backgroundColor: 'orange',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor2: {
    backgroundColor: '#594f8d',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor3: {
    backgroundColor: '#e91e63',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  paperColor4: {
    backgroundColor: '#039be5',
    color: 'white',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

function TabPanel(props) {
  const { children, value, index, type, ...other } = props;
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteUser, setDeleteUser] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({
    left: 'No',
    right: 'Yes',
    message: '',
  });
  async function getData() {
    dispatch(setLoader(true));
    let queryFor = props.type;

    let result = await api.userManagement.getAllUsers(``);
    result = result && result.data && result.data.data ? result.data.data : [];

    let queryParam = ``;
    switch (queryFor) {
      case 'pending-user':
        result = await result
          .filter((r) => !!r.isVerified === false)
          .sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });
        break;
      case 'approved-user':
        result = await result
          .filter((r) => !!r.isVerified === true)
          .sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });

        break;
      default:
        queryParam = ``;
    }

    console.log(result);
    setRows(result);

    dispatch(setLoader(false));
  }

  useEffect(() => {
    getData();
  }, []);

  function removeIndex(arr, index) {
    arr.splice(index, 1);
    return arr;
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <DeleteDialog
        open={deleteUser}
        data={deleteUserData}
        handleClose={() => {
          setDeleteUser(false);
        }}
        handleDelete={(oldData) => {
          new Promise(async (resolve, reject) => {
            dispatch(setLoader(true));
            try {
              await api.userManagement.deleteUserById(oldData._id);
              const data = rows.filter((row) => row._id != oldData._id);
              setRows(data);
              dispatch(setSnackbar(true, 'success', 'User Deleted'));
              resolve();
            } catch (err) {
              setRows(rows);
              dispatch(setSnackbar(true, 'error', 'User Deleted'));
              reject();
            }
            dispatch(setLoader(false));
            setDeleteUser(false);
          });
        }}
      />

      {value === index && (
        <Box p={3}>
          <MaterialTable
            tableLayout="auto"
            icons={{
              Check: () => <Check />,
              Export: () => <SaveAlt />,
              Filter: () => <FilterList />,
              FirstPage: () => <FirstPage />,
              LastPage: () => <LastPage />,
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              ThirdStateCheck: () => <Remove />,
              ViewColumn: () => <ViewColumn />,
              DetailPanel: () => <ChevronRight />,
            }}
            title="User Management"
            columns={[
              {
                title: 'Mobile No',
                field: 'mobileNo',
                render: (rowData) => {
                  return (
                    <Tooltip
                      title={
                        !!rowData.isMobileVerified
                          ? 'Mobile Number is verified'
                          : 'Mobile Verification is pending'
                      }
                    >
                      <div>{rowData.mobileNo}</div>
                    </Tooltip>
                  );
                },
              },
              { title: 'Name', field: 'name' },
              { title: 'Email', field: 'email' },
              { title: 'Address', field: 'address' },
              {
                title: 'City',
                field: 'city',
                hiddenByColumnsButton: true,
                hidden: true,
              },
              {
                title: 'Pin Code',
                field: 'pincode',
                hiddenByColumnsButton: true,
                hidden: true,
              },
              {
                title: 'Alternate Mobile No',
                field: 'alternateMobileNo',
                hiddenByColumnsButton: true,
                hidden: true,
              },
              {
                title: 'Created At',
                field: 'createdAt',
                hiddenByColumnsButton: true,

                type: 'datetime',
                hidden: true,
              },
              // {
              //   title: 'Paid Amount',
              //   field: 'quickBuyPayment.paymentAmount',
              //   hiddenByColumnsButton: true,
              //   hidden: true,
              // },
              // {
              //   title: 'Paid Status',
              //   field: 'quickBuyPayment.paymentStatus',
              //   hiddenByColumnsButton: true,
              //   hidden: true,
              // },
              // {
              //   title: 'Paid Remark',
              //   field: 'quickBuyPayment.paymentRemark',
              //   hiddenByColumnsButton: true,
              //   hidden: true,
              // },
              {
                title: 'Aadhaar',
                field: 'aadhaar',
                filtering: false,
                grouping: false,
                render: (rowData) => {
                  return rowData.aadhaar !== 'Pending' ? (
                    rowData.aadhaar
                  ) : (
                    <Alert severity="error">Pending</Alert>
                  );
                },
              },

              {
                title: 'Verified',
                field: 'isVerified',
                filtering: false,
                grouping: false,
                render: (rowData) => {
                  return (
                    <Tooltip
                      title={
                        rowData.isVerified === true
                          ? 'User is verified'
                          : rowData.isMobileVerified
                          ? rowData.aadhaarUrl && rowData.aadhaar
                            ? `User is verified`
                            : `User's Aadhaar Verification is pending`
                          : `User's mobile number is not verfied`
                      }
                    >
                      <Alert severity={rowData.isVerified === true ? 'success' : 'error'}>
                        {rowData.isVerified === true ? 'Verified' : 'Pending'}
                      </Alert>
                    </Tooltip>
                  );
                },
              },
            ]}
            data={
              rows
                ? rows.map((row) => ({
                    ...row,
                    aadhaar: row.aadhaar ? row.aadhaar : 'Pending',
                    panCard: row.panCard ? row.panCard : 'Pending',
                  }))
                : []
            }
            localization={{
              body: {
                emptyDataSourceMessage: 'No Users found',
              },
              pagination: { labelRowsPerPage: 10 },
              grouping: {
                placeholder: 'Group By',
              },
            }}
            options={{
              actionsColumnIndex: -1,
              grouping: false,
              exportButton: false,
              add: false,
              columnsButton: true,
              exportAllData: true,
              exportButton: true,
              add: false,
              headerStyle: {
                backgroundColor: '#4caf50',
                color: '#FFF',
                widtth: '100vw',
                fontWeight: 'bold',
              },
              loadingType: 'overlay',
              paginationType: 'stepped',
              pageSize: 5,
              pageSizeOptions: [5, 15, 30],
              rowStyle: (rowData) => ({
                backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
                textAlign: 'center',
              }),
              filtering: true,
            }}
            onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
            actions={[
              {
                icon: () => {
                  return (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      <span>+</span>
                      &nbsp; &nbsp; Add User
                    </Button>
                  );
                },
                tooltip: 'Add User',
                isFreeAction: true,
                onClick: (event) => {
                  history.push(`/users/add`);
                },
              },
              {
                icon: () => (
                  <div
                    style={{
                      display: 'block',
                    }}
                  >
                    <HiEye />
                    <div
                      style={{
                        fontSize: '10px',
                      }}
                    >
                      View
                    </div>
                  </div>
                ),
                tooltip: 'View User',
                onClick: (event, rowData) => {
                  history.push(`/users/view/${rowData._id}`);
                },
              },
              // {
              //   icon: () => (
              //     <div
              //       style={{
              //         display: 'block',
              //       }}
              //     >
              //       <MdModeEdit />
              //       <div
              //         style={{
              //           fontSize: '10px',
              //         }}
              //       >
              //         Edit
              //       </div>
              //     </div>
              //   ),
              //   tooltip: 'Edit User',
              //   onClick: (event, rowData) => {
              //     history.push(`/users/add/${rowData._id}`);
              //   },
              // },
              // {
              //   icon: () => (
              //     <div
              //       style={{
              //         display: 'block',
              //       }}
              //     >
              //       <MdDeleteForever />
              //       <div
              //         style={{
              //           fontSize: '10px',
              //         }}
              //       >
              //         Delete
              //       </div>
              //     </div>
              //   ),
              //   tooltip: 'Delete User',
              //   onClick: (event, oldData) => {
              //     setDeleteUserData({
              //       title: 'Confirm Delete',
              //       left: 'No',
              //       right: 'Yes',
              //       message: `Are you sure you want to delete this user with mobile number ${oldData.mobileNo} and name ${oldData.name}?
              //       `,
              //       oldData: oldData,
              //     });
              //     setDeleteUser(true);

              //     // new Promise(async (resolve, reject) => {
              //     //   dispatch(setLoader(true));
              //     //   try {
              //     //     await api.userManagement.deleteUserById(oldData._id);
              //     //     const data = rows.filter((row) => row._id != oldData._id);
              //     //     setRows(data);
              //     //     dispatch(setSnackbar(true, 'success', 'User Deleted'));
              //     //     resolve();
              //     //   } catch (err) {
              //     //     setRows(rows);
              //     //     dispatch(setSnackbar(true, 'error', 'User Deleted'));
              //     //     reject();
              //     //   }
              //     //   dispatch(setLoader(false));
              //     // });
              //   },
              // },
            ]}
          />
        </Box>
      )}
    </div>
  );
}

const UserManagement = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs />
      <AppBar
        position="static"
        color="default"
        style={{
          width: '40%',
          marginLeft: '2%',
          backgroundColor: 'white',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            style={{
              background: 'none',
            }}
            label="Pending Users"
            {...a11yProps(0)}
          />
          <Tab label="Approved Users" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} type={'pending-user'}>
        Pending Users
      </TabPanel>
      <TabPanel value={value} index={1} type={'approved-user'}>
        Approved Users
      </TabPanel>
    </div>
  );
};
export default UserManagement;
