import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import rootReducer from './reducers/index';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';

const store = createStore(rootReducer, applyMiddleware(thunk));
export const appHistory = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={appHistory}>
      <CssBaseline />
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
