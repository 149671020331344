export default {
  env: process.env['REACT_APP_ENV'],
  api: {
    baseUrl: process.env['REACT_APP_API'],
    url: process.env['REGION_NAME'],
  },
  region: process.env['REGION'],
  accessKey: process.env['ACCESSKEY'],
  secretKey: process.env['SECRETKEY'],
};

console.log('ppppp', process.env);
