import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RootRef from '@material-ui/core/RootRef';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import getObjectDiffValues from '../../../utils/objectDiff';
import { FormControlLabel, Switch as MaterialSwitch, Divider } from '@material-ui/core';
import { Switch } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';

import TextField from '@material-ui/core/TextField';

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
export default function GenericFileUpload(props) {
  const [open, setOpen] = React.useState(false);
  const [highlighted, setHighlighted] = React.useState(false);
  const [toUpload, setToUpload] = useState([]);
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [files, setFiles] = useState([]);
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [sentData, setSentData] = useState(null);
  const [makingApiCall, setMakingApiCall] = useState(false);
  const [spinnerMessage, setSpinnerMessage] = useState(false);
  const dispatch = useDispatch();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setSpinnerMessage('Uploading the file');

      setHighlighted(false);
      setUploadedUrl('');
      setMakingApiCall(true);
      try {
        let tempToUpload = [...acceptedFiles];
        let urls = await Promise.all(
          tempToUpload.map(async (file) => {
            try {
              if (file.type) {
                let data = await api.fileUpload.generatePresignedUrl(props.fileType, file.type);
                let d = await axios.put(data.data.url, file, {
                  headers: {
                    'Content-Type': file.type,
                  },
                });
                return data.data.url ? data.data.url.split('?')[0] : null;
              } else {
                return file;
              }
            } catch (err) {
              return null;
            }
          }),
        );
        if (urls) {
          setUploadedUrl(urls[0]);
        }
        setToUpload(tempToUpload);
        let modifiles = acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        });
        let tempFile = [...modifiles];

        setFiles(tempFile);
      } catch (err) {
        setMakingApiCall(false);
      }

      setMakingApiCall(false);
    },
  });

  const images = files.map((file, index) => (
    <>
      <img
        src={file && file.preview ? file.preview : file}
        className="img-thumbnail mt-2"
        style={{
          margin: '20px',
          objectFit: 'contain',
        }}
        height={200}
        width={220}
        alt="preview"
      />
      {!isViewOnly && (
        <IconButton
          color="secondary"
          aria-label="upload picture"
          component="span"
          onClick={() => {}}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  ));
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title">Upload {props.documentName}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Upload {props.documentName}</Typography>

          <div>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12}>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />

                  <p> Drag 'n' drop {props.documentName} here, or click here to select file</p>
                </div>
                {images}
              </Grid>
            </Grid>
            <Typography gutterBottom></Typography>
          </div>
          <div>
            <br />
            OR
            <br />
            <br />
            <br />
            <TextField
              id="outlined-basic"
              label="Add a url from external source"
              variant="outlined"
              fullWidth
              value={uploadedUrl}
              onChange={(e) => {
                setUploadedUrl(e.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              props.handleClose();
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            autoFocus
            onClick={() => {
              if (uploadedUrl) {
                props.passData({
                  url: uploadedUrl,
                });
                dispatch(
                  setSnackbar(
                    true,
                    'success',
                    'File Uploaded, Please click on sumbit for association',
                  ),
                );
              } else {
                dispatch(setSnackbar(true, 'error', 'Something went wrong'));
              }
              props.handleClose();
            }}
            color="primary"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
