import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Icon from '@material-ui/core/Icon';

const useCardStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  leftText: {
    textAlign: 'left',
  },
  rightText: {
    textAlign: 'right',
  },
});
export default function DashBoardCard(props) {
  const classes = useCardStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card style={props.style ? props.style : {}}>
      <CardContent>
        <Typography className={{ ...classes.title }} color="textSecondary" gutterBottom>
          {props.title}
        </Typography>
        <Grid container direction="row" justify="space-around" alignItems="center">
          <Grid item xs={props.icon ? 8 : 12}>
            <Typography variant="h5" component="h2">
              {props.value}
            </Typography>
          </Grid>
          {props.icon ? (
            <Grid style={{ textAlign: 'right' }} item xs={4}>
              <Icon>{props.icon}</Icon>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={props.onClick}>
          {props.navigationText}
        </Button>
        <IconButton
          aria-label="share"
          style={{
            marginLeft: 'auto',
          }}
        >
          <ArrowForwardIosIcon onClick={props.onClick} />
        </IconButton>
      </CardActions>
    </Card>
  );
}
