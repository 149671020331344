import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RootRef from '@material-ui/core/RootRef';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import getObjectDiffValues from '../../../utils/objectDiff';
import { FormControlLabel, Switch as MaterialSwitch, Divider } from '@material-ui/core';
import { Switch } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Grid from '@material-ui/core/Grid';
import { FixedSizeList } from 'react-window';

import { Typeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Field, ErrorMessage } from 'formik';
import TextError from '../../common/TextError';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as moment from 'moment';
import { api } from '../../../api/service';

var FormData = require('form-data');

export default function QuickBuyBuyers(props) {
  const [open, setOpen] = React.useState(false);
  const [highlighted, setHighlighted] = React.useState(false);
  const [toUpload, setToUpload] = useState([]);
  const [isViewOnly, setIsViewOnly] = useState(true);
  const [files, setFiles] = useState([]);
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [aadhaarData, setAadhaarData] = useState(null);
  const [fetchAadharApi, setFetchAadharApi] = useState(false);
  const [spinnerMessage, setSpinnerMessage] = useState(false);
  const dispatch = useDispatch();

  const [currentOption, setCurrentOption] = useState('');
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [remarks, setRemarks] = useState('');

  useEffect(() => {
    setSelectedTrack(null);
  }, []);
  return (
    <div>
      <Dialog
        onClose={() => {
          setSelectedTrack(null);
          props.handleClose();
        }}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title">Enquiries for {props.vehicleName}</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            The following users are interested for this quick buy {props.vehicleName}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Paper style={{ maxHeight: 400, overflow: 'auto' }}>
                <List>
                  <ListItem>
                    <ListItemText primary={'Name'} />
                    <br />
                    <ListItemText primary={'Mobile No'} />
                    <ListItemText primary={'Email'} />
                    <ListItemText primary={'Created At'} />
                  </ListItem>
                  {props.tracking &&
                    props.tracking.map((track) => {
                      return track && track.enquiryBy ? (
                        <>
                          <ListItem
                            onClick={(selectedRow) => {
                              setSelectedTrack(track);
                              setCurrentOption(track.quickBuyStatus);
                              setRemarks(track.remark);
                            }}
                          >
                            <ListItemText primary={track.enquiryBy.name} />
                            <br />
                            <ListItemText primary={track.enquiryBy.mobileNo} />
                            <ListItemText primary={track.enquiryBy.email} />
                            <ListItemText
                              primary={moment(track.createdAt).format('DD - MMM YYYY hh:mm a')}
                            />
                          </ListItem>
                          <Divider />
                        </>
                      ) : (
                        <></>
                      );
                    })}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              {selectedTrack ? (
                <>
                  {' '}
                  Status for {selectedTrack.enquiryBy.name}
                  <br />
                  <br />
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentOption}
                    onChange={(event) => {
                      setCurrentOption(event.target.value);
                    }}
                  >
                    <MenuItem value={'Enquiry Recieved'}>Enquiry Recieved</MenuItem>
                    <MenuItem value={'Acknowledged'}>Acknowledged</MenuItem>
                    <MenuItem value={'Document Verified'}>Document Verified</MenuItem>
                    <MenuItem value={'Ready for Delivery'}>Ready for Delivery</MenuItem>
                    <MenuItem value={'Payment Recieved Full'}> Payment Recieved Full</MenuItem>
                    <MenuItem value={'Payment Recived Partial'}> Payment Recived Partial</MenuItem>
                    <MenuItem value={'Delivered'}>Delivered</MenuItem>
                  </Select>
                  <br />
                  <br />
                  <br />
                  <FormControl fullWidth variant="outlined">
                    <div htmlFor="outlined-adornment-amount">Remarks</div>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      value={remarks}
                      onChange={(event) => {
                        setRemarks(event.target.value);
                      }}
                      labelWidth={60}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      let response = await api.quickbuyVehicleListing.updateQuickBuyStatus(
                        selectedTrack._id,
                        {
                          status: currentOption,
                          remark: remarks,
                        },
                      );
                      console.log(response.data);

                      window.location.reload();
                    }}
                  >
                    Update
                  </Button>{' '}
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              props.handleClose();
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            autoFocus
            onClick={() => {
              if (aadhaarData && aadhaarData['aadhaar_number']) {
                props.passData({
                  url: uploadedUrl,
                  aadhaarData: aadhaarData,
                });
              } else {
                dispatch(setSnackbar(true, 'error', 'Not a valid Aadhaar card'));
              }
              props.handleClose();
            }}
            color="primary"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
