import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

const firstLetterUppercase = (string) => {
  if (string == 'login') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const Breadcrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const pathnames = pathname.split('/').filter((x) => x);
  return (
    <Paper
      elevation={2}
      style={{
        padding: '1%',
        margin: '2%',
      }}
    >
      <MUIBreadcrumbs aria-label="breadcrumb">
        {pathnames.length > 0 ? (
          <Link onClick={() => history.push('/')}>Dashboard</Link>
        ) : (
          <Typography color="textPrimary"> Dashboard </Typography>
        )}
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography color="secondary" key={name}>
              {firstLetterUppercase(name)}
            </Typography>
          ) : (
            <Link key={name} onClick={() => history.push(routeTo)}>
              {firstLetterUppercase(name)}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </Paper>
  );
};

export default withRouter(Breadcrumbs);
