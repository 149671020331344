import * as JWT from 'jwt-decode';
import config from '../config/config';
// Action Creators

const setUser = (payload) => ({ type: 'SET_USER', payload });

export const logUserOut = () => ({ type: 'LOG_OUT' });

// Methods

export const fetchUser = (userInfo) => (dispatch) => {
  fetch(`${config.api.baseUrl}/api/auth/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(userInfo),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data && data.roles && data.roles.includes('ROLE_ADMIN')) {
        localStorage.setItem('token', data.accessToken);
        dispatch(setUser(data));
      } else {
        dispatch(loginError(data.message ? data.message : 'Something went wrong'));
      }
    })
    .catch((err) => {});
};

export const logout = () => (dispatch) => {
  dispatch(logUserOut());
};

export const loginError = (message) => (dispatch) => {
  dispatch({ type: 'LOGIN_ERROR', message });
};

export const autoLogin = () => (dispatch) => {
  let token = localStorage.getItem('token');
  let user = token ? JWT(token) : undefined;
  if (user) {
    if (user.roles && user.roles.includes('admin')) {
      dispatch(setUser(user));
    }
  } else {
    //throw error
  }
};
