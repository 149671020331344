import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { api } from '../../../api/service';
import { setLoader } from '../../../reducers/loader.reducer';
import { setSnackbar } from '../../../reducers/snackbar.reducer';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { HiEye } from 'react-icons/hi';
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteForever } from 'react-icons/md';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    tabs: {
      backgroundColor: 'red',
    },
    AppBar: {
      background: 'transparent',
      boxShadow: 'none',
      width: '60%',
    },
  },
  AppBar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function TabPanel(props) {
  const { children, value, index, type, ...other } = props;
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  async function getData() {
    dispatch(setLoader(true));
    let queryFor = props.type;
    let queryParam = ''; // `status=${queryFor ? queryFor : 'for-sale'}`;
    const result = await api.quickbuyVehicleListing.getAllQuickBuysEnquires(queryParam);
    setRows(result.data.data ? result.data.data : []);
    dispatch(setLoader(false));
  }

  useEffect(() => {
    getData();
  }, []);

  function removeIndex(arr, index) {
    arr.splice(index, 1);
    return arr;
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <MaterialTable
            tableLayout="auto"
            icons={{
              Check: () => <Check />,
              Export: () => <SaveAlt />,
              Filter: () => <FilterList />,
              FirstPage: () => <FirstPage />,
              LastPage: () => <LastPage />,
              NextPage: () => <ChevronRight />,
              PreviousPage: () => <ChevronLeft />,
              Search: () => <Search />,
              ThirdStateCheck: () => <Remove />,
              ViewColumn: () => <ViewColumn />,
              DetailPanel: () => <ChevronRight />,
            }}
            title="Quick Buy Enquires"
            columns={[
              {
                title: 'Name',
                field: 'enquiryBy.name',
              },
              { title: 'Email', field: 'enquiryBy.email' },
              { title: 'Mobile No', field: 'enquiryBy.mobileNo' },
              { title: 'Brand', field: 'enquiryFor.brand' },
              { title: 'Model', field: 'enquiryFor.model' },
              {
                title: 'Enquiry Status',
                field: 'quickBuyStatus',
                render: (rowData) => {
                  return <div>{rowData.quickBuyStatus}</div>;
                },
              },
            ]}
            data={
              rows
                ? rows.map((row) => ({
                    ...row,
                    // status: row.status ? row.status : 'for-sale',
                    sellCost: Intl.NumberFormat('en-IN').format(row.sellCost),
                  }))
                : []
            }
            localization={{
              body: {
                emptyDataSourceMessage: 'No Quick Buy Enquires Found',
              },
              grouping: {
                placeholder: 'Group By',
              },
            }}
            options={{
              grouping: true,
              exportButton: false,
              add: false,
              columnsButton: true,
              exportAllData: true,
              exportButton: true,
              add: false,
              headerStyle: {
                backgroundColor: '#4caf50',
                color: '#FFF',
                widtth: '100vw',
                fontWeight: 'bold',
              },
              loadingType: 'overlay',
              paginationType: 'stepped',
              pageSize: 5,
              pageSizeOptions: [5, 15, 30],
              rowStyle: (rowData) => ({
                backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
                textAlign: 'center',
              }),
              filtering: true,
            }}
            detailPanel={(rowData) => {
              return <DetailPanelForEnquiry rowData={rowData} />;
            }}
          />
        </Box>
      )}
    </div>
  );
}

function DetailPanelForEnquiry({ rowData }) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState('');
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [loading, setLoading] = useState(false);

  const changeStatus = async (newStatus) => {
    dispatch(setLoader(true));
    try {
      let response = await api.enquiry.updateEnquiryStatus(
        rowData._id,
        newStatus.toUpperCase(),
        value,
      );
      dispatch(setSnackbar(true, 'success', `Status Updated to attempted`));
      window.location.reload(false);
    } catch (err) {
      dispatch(setSnackbar(true, 'error', `Unable to Update status to attempted`));
    }
    dispatch(setLoader(false));
  };
  useEffect(() => {
    setCurrentOption(rowData.quickBuyStatus);
    setRemarks(rowData.remark);
  }, []);
  return (
    <div
      style={{
        marginLeft: '5%',
        marginTop: '1%',
      }}
    >
      {' '}
      <b>Enquiry Status</b>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid
          item
          xs={5}
          style={{
            width: '50%',
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentOption}
            fullWidth
            onChange={(event) => {
              setCurrentOption(event.target.value);
              console.log(rowData.quickBuyStatus, event.target.value);
              if (rowData.quickBuyStatus !== event.target.value) {
                setRemarks('');
              } else {
                setRemarks(rowData.remark);
              }
            }}
          >
            <MenuItem value={'Enquiry Recieved'}>Enquiry Recieved</MenuItem>
            <MenuItem value={'Acknowledged'}>Acknowledged</MenuItem>
            <MenuItem value={'Document Verified'}>Document Verified</MenuItem>
            <MenuItem value={'Ready for Delivery'}>Ready for Delivery</MenuItem>
            <MenuItem value={'Payment Recieved Full'}> Payment Recieved Full</MenuItem>
            <MenuItem value={'Payment Recived Partial'}> Payment Recived Partial</MenuItem>
            <MenuItem value={'Delivered'}>Delivered</MenuItem>
          </Select>
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            marginLeft: '1%',
          }}
        >
          <>
            <FormControl variant="outlined">
              <TextField
                label="Remarks"
                id="outlined-start-adornment"
                value={remarks}
                multiline
                rows={4}
                fullWidth
                style={{
                  width: '250%',
                }}
                onChange={(event) => {
                  setRemarks(event.target.value);
                }}
                variant="outlined"
              />
            </FormControl>
            <br />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={async () => {
                setLoading(true);
                try {
                  let response = await api.quickbuyVehicleListing.updateQuickBuyStatus(
                    rowData._id,
                    {
                      status: currentOption,
                      remark: remarks,
                    },
                  );
                  console.log(response.data);

                  window.location.reload();
                } catch (err) {
                  dispatch(setSnackbar(true, 'error', 'Update Failed'));
                }
                setLoading(false);
              }}
              disabled={loading}
            >
              Update
            </Button>{' '}
          </>
        </Grid>
      </Grid>
      <center>
        <div
          style={{
            padding: '9px',
          }}
        ></div>
      </center>
    </div>
  );
}

function QuickBuyEnquires() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs />
      <AppBar
        position="static"
        color="default"
        style={{
          width: '40%',
          marginLeft: '2%',
          backgroundColor: 'white',
        }}
      >
        {/* <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            style={{
              background: 'none',
            }}
            label="For Sale Vehicles"
            {...a11yProps(0)}
          />
          <Tab label="Sold Vehicles" {...a11yProps(1)} />
        </Tabs> */}
      </AppBar>
      <TabPanel value={value} index={0} type={'for-sale'}>
        For Sale
      </TabPanel>
      <TabPanel value={value} index={1} type={'sold'}>
        Sold
      </TabPanel>
    </div>
  );
}

export default withRouter(QuickBuyEnquires);
